export const titleCase = str => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};
export const getEncodedEmail = email => {
    try {
        if (email != "") { return email.substring(0, 2) + "*********@" + email.split("@")[1] }
        else { return ""; }
    }
    catch (e) {
        return "";
    }
};
export const isHtml = input => {
    if (input)
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    else
        return false;
};
export const isEmailContain = input => {
    if (input)
        return input.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g);
    else
        return false;
}
export const isInvalid = input => {
    if (input)
    return (/<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input) || input.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g)?.length > 0 || input.match(/[(http(s)?:\/\/.)?(www\.)?]{1}[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)?.length > 0);
    else
        return false;
}

export function checkImageURL(url) {
    return new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve(true);
        img.onerror = () => resolve(false);
        img.src = url;
    });
}
// Define a function daylights_savings that takes a Date object dt as input
export function daylightsSavings(dt) {
    // Initialize a variable dst to null to store the daylight savings time offset
    var dst = null;
    // Iterate over the months of the year
    for (var i = 0; i < 12; ++i) {
        // Create a new Date object for the current year and month
        var d = new Date(dt.getFullYear(), i, 1);
        // Retrieve the timezone offset for the current date
        var offset = dt.getTimezoneOffset();

        // Check if the daylight savings time offset has been set
        if (dst === null)
            dst = offset;
        // If the current offset is less than the previously stored offset, update the dst variable
        else if (offset < dst) {
            dst = offset;
            // Exit the loop if a lower offset is found
            break;
        }
        // If the current offset is greater than the previously stored offset, exit the loop
        else if (offset > dst)
            break;
    }
    // Return 1 if the timezone offset of the provided date matches the daylight savings offset, else return 0
    return (dt.getTimezoneOffset() == dst) | 0;
}
