import React, { useContext,  useEffect } from "react";
import StarRating from "../../components/Core/Rating";
import { sinceDate } from "../../utils/addDays";
import GlobalContext from "../../context/GlobalContext"; 
import Icon from '@mui/material/Icon';

const FeaturedJobs = (props) => {
    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    
    useEffect(() => {
        
    }, [props.bindData]);

    function toRoundOff(n) {
       
        if ((n - Math.floor(n)) != 0) {
            var dec_value = parseFloat(n).toFixed(1);
            return dec_value;

        }
        else {
            return n;
        }

    }
    return (
        <> {(props.bindData.experts) && (props.bindData.experts.length != 0) &&
            <section className="section featured-experts-group">
                <div className="container-fluid">

                    <div className="featured-experts-title">
                        <div className="section-title">
                            <h2>Featured <span className="text-theme">Experts </span></h2>
                        </div>

                        <div className="tab-mobile-hide">
                            <button className="btn btn-orange-outline" onClick={(e) => { window.location.href="/searchexpert"; }}  >Explore more</button>
                        </div>
                    </div>

                    <div className="expert-info-block">
                    {(props.bindData && props.bindData.experts) && props.bindData.experts.map((expert, index) => {

                        return (
                            <div className="expert-wrapper-block card" key={index}>

                            <div className="expert-data m-b-20">
                                <div className="expert-profile">
                                    <div className="expert-img">

                                            {(expert.avatar) && <img id={"img" + index} className="pointer w-50" src={s3URL + expert.avatar} onError={(img) => {  img.target.src = '../images/user-default.png'; }} onClick={(e) => { window.location.href = "expert/" + expert.sub; }} />}
                                             
                                            
                                    </div>
                                    <div className="d-flex flex-column">
                                            <strong className="expert-name pointer" onClick={(e) => { window.location.href = "expert/" + expert.sub; }}>  {(expert.firstname) ? expert.firstname : ''} {" "}   {(expert.lastname) ? expert.lastname : ''} <img className="verified-img m-l-5" src="../images/verified_img.svg" alt="verified_img.svg"/></strong>
                                            <span className="expert-desg"> {(expert.title) ? expert.title : ''}</span>
                                             
                                    </div>
                                </div>
                                    {(expert.avgrating == null) ? <> 
                                     <span className=" pt-1 pl-3 font-size-2 font-weight-semibold pr-5"></span></> :
                                        <>
                                            <StarRating rating={(expert.avgrating) ?  (expert.avgrating) : 0} /> 
                                            <span  >{(expert.avgrating) ? toRoundOff(expert.avgrating.toString()) + "/5" : 0}
                                            </span></>
                                            
                                            } 

                                            <br/>
                                            <span>Active since  <strong>{(expert.datecreated) ? sinceDate(expert.datecreated, new Date()).replace('ago', '') : ''}
                                            
                                            
                                            </strong></span>
                            </div>

                            <div className="info-desc">

                                <div className="d-flex justify-content-between align-item-center m-b-20">
                                    <div className="info-meta m-0">
                                            <span className="text-theme m-icon material-icons m-r-10">schedule</span><span className="one-line"> {(expert.weeklyhours) ? expert.weeklyhours : ''}</span>
                                    </div>
                                    <div className="work-pay">
                                            {(expert.dailyrate) && <> <span className=""></span><strong>${new Intl.NumberFormat('en-US').format(parseInt(expert.dailyrate))}</strong><span className="text-sm">Daily</span></>}
                                    </div>
                                </div>

                                <div className="info-meta">
                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                        {(expert.explevel) && expert.explevel.map((data, index) => {
                                            return (<span className="one-line" key={index}>  {(data.level) ? data.level : ''}</span> )
                                        })}
                                </div>

                                <div className="info-meta">
                                        <span className="text-theme m-icon material-icons m-r-10">location_on</span><span className="one-line">  {expert.city}, {expert.countrycode}</span>
                                </div>

                            </div>

                                <div className="two-line m-b-20"  > {expert.profile.replace(/(<([^>]+)>)/ig, '') }</div>

                            <div className="expert-link d-flex justify-content-end">
                                    <button className="btn btn-link " onClick={(e) => { window.location.href = "expert/" + expert.sub; }}>View all <span className="m-icon material-icons">arrow_forward</span></button>
                            </div>

                        </div>

                         
                        )
                    })} 

                    </div>

                    <div className="tab-mobile-display">
                        <button className="btn btn-sm btn-orange-outline" onClick={(e) => { window.location.href = "/searchexpert"; }} >Explore more</button>
                    </div>

                </div>
            </section>}


            {(props.bindData.jobs) && (props.bindData.jobs.length != 0) && <section className="section featured-experts-group  bg-gray">
                <div className="container-fluid">

                    <div className="featured-experts-title">
                        <div className="section-title">
                            <h2>Featured <span className="text-theme">Jobs </span></h2>
                        </div>

                        <div className="tab-mobile-hide">
                            <button className="btn btn-orange-outline" onClick={(e) => { window.location.href = "/searchjob"; }} >Explore more</button>
                        </div>
                    </div>

                    <div className="expert-info-block">

                        {(props.bindData && props.bindData.jobs) && props.bindData.jobs.map((job, index) => {

                            return (
                                <div className="expert-wrapper-block card" key={ index}>

                            <div className="coplience-experts d-flex flex-column">
                                        <h6 onClick={(e) => { window.location.href = "job/" + job.guid; }} className="m-b-20 pointer" ><strong >   {(job.title) ? job.title : ''}</strong></h6>
                                <div className="d-flex align-item-center m-b-20">

                                    <div className="icon-frame d-flex m-r-5">
                                                {(job.logo) && <img id={"img" + index} onClick={(e) => { window.location.href = "/client/" + job.sub; }} className="m-r-5 pointer" style={{ borderRadius: '12px' }} src={s3URL + job.logo} onError={(img) => { img.target.src = '../images/user-default.png'; }} alt="icon-frame" />}
                                                <strong onClick={(e) => { window.location.href = "/client/" + job.sub; }} className=" pointer">{job.company}</strong>
                                    </div>
                                            
                                    <div className="info-meta">
                                                <span className="text-theme m-icon material-icons">location_on</span><span className="one-line"> {job.city}, {job.countrycode}</span>
                                    </div>
                                </div>

                            </div>

                            <div className="info-desc">

                                <div className="d-flex justify-content-between align-item-center m-b-20">
                                    <div className="info-meta m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">calendar_month</span><span className="one-line"><span>2 to 8 months</span></span>
                                    </div>
                                    <div className="work-pay">
                                            {((job.dailyrate) || (job.fixedrate) || (job.hourlyrate) || (job.rateto && job.ratefrom)) ?<><span></span><strong>${(job.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(job.dailyrate)) : ((job.fixedrate) ? new Intl.NumberFormat('en-US').format(parseInt(job.fixedrate)) : (job.hourlyrate) ? new Intl.NumberFormat('en-US').format(parseInt(job.hourlyrate)) : (job.ratefrom && job.rateto) ? new Intl.NumberFormat('en-US').format(parseInt(job.ratefrom)) + ' - ' + new Intl.NumberFormat('en-US').format(parseInt(job.rateto)) : '')}</strong><span className="text-sm"> </span></>:''}
                                    </div>
                                </div>
                                 
                                <div className="info-meta">
                                            <span className="text-theme m-icon material-icons m-r-10">home</span><span className="one-line">{(job.type) ? job.type : ''}</span>
                                </div>

                                <div className="info-meta">
                                            <span className="text-theme m-icon material-icons m-r-10">work</span><span className="one-line">  {(job.explevel) ? job.explevel : ''}</span>
                                </div>

                                <div className="info-meta">
                                            <span className="text-theme m-icon material-icons m-r-10">timer</span><span className="one-line"> {(job.projectlength) ? job.projectlength : ''}</span>
                                </div>

                            </div>

                                    <p className="two-line m-b-20">{job.jobdesc.replace(/(<([^>]+)>)/ig, '')}</p>

                            <div className="expert-link d-flex justify-content-end">
                                        <button className="btn btn-link" onClick={(e) => { window.location.href = "job/" + job.guid; }}>View all <span className="m-icon material-icons">arrow_forward</span></button>
                            </div>

                        </div>


                            )
                        })}
                    </div>

                    <div className="tab-mobile-display">
                        <button className="btn btn-sm  btn-orange-outline" onClick={(e) => { window.location.href = "/searchjob"; }} >Explore more</button>
                    </div>

                </div> 
            </section>}

            <section className="section comprehensive-block p-relative">

                <div className="container">
                    <div className="comprehensive-block-title">
                        <div className="section-title m-b-10">
                            
                            <h2>Most <span className="text-theme">Comprehensive Compliance</span>  Job Portal</h2>
                            
                        </div>
                        {gContext.userInfo.email == '' && <>    <div className="comprehensive-btn m-t-10">
                               <button className="btn   btn-orange m-r-20" onClick={(e) => {
                                e.preventDefault();
                                gContext.toggleSignInModal();
                            }}><Icon className="text-white m-icon material-icons">logout</Icon> <span className="text-white">Login</span></button>
                            <button id="signup1" className="btn   btn-dark-outline" onClick={(e) => {
                                    e.preventDefault();
                                    gContext.toggleSignUpModal();
                                }}><Icon className="m-icon material-icons">person</Icon> <span>Sign up</span></button>
                            </div></>}
                    </div>
                </div>
            </section>

            {/* <!-- FeaturedJobs Area -->  */}
             
        </>
    );
};

export default FeaturedJobs;
