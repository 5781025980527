import React, { useContext, useState, useEffect } from "react";
import StarRating from "../../components/Core/Rating";
import { sinceDate } from "../../utils/addDays";
import GlobalContext from "../../context/GlobalContext";
import ReactCountryFlag from "react-country-flag"; 
import DOMPurify from 'isomorphic-dompurify';
import ModalPostedJobs from "../../sections/ClientDashboard/ModalInvite";
 
export default function ExpertSearchgrid(props) {
    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;  
    const [experts, setExperts] = useState([{}]); 
    const [expertData, setExpert] = useState({});     
    const [showJobs, setShowJobs] = useState(false);
    
   function toRoundOff(n) { 

            if ((n - Math.floor(n)) != 0) {
                var dec_value = parseFloat(n).toFixed(1);
                return dec_value;

            }
            else {
                return n;
            }

        } 
 

    useEffect(() => {

        setExperts(props.jobsList);
    }, [props.jobsList]);
    return (
        <>        <div className="row w-full">
                {(experts) && experts.map((expert, index) => {

                    return (<>
                         

                                <div className="col-lg-6 col-md-6 col-sm-12 m-b-20  ">
                                    <div className="card list-block grid-block">
                                        <div className="expert-info-details">

                                            <div className="expert-data">
                                                <div className="expert-profile m-0 align-items-start">
                                            <div className="expert-img">
                                                {(expert.avatar) && <img className="  pointer" id={"image" + index} onError={(img) => { img.target.src = '../images/user-default.png'; }}  src={(expert.avatar && expert.avatar != "" && expert.avatar.toLowerCase() != "null") ? s3URL + expert.avatar : "../images/user-default.png"} onClick={() => { window.location.href = "expert/" + expert.sub; }} alt={(expert.firstname) ? expert.firstname : '' + (expert.lastname) ? " " + expert.lastname : ''} />}
                            </div>

                                                        <div className="d-flex flex-column">

                                                <div className="d-flex">  <strong onClick={() => { window.location.href = "expert/" + expert.sub; }}><h1 className="expert-name pointer" onClick={() => { window.location.href = "expert/" + expert.sub; }}> {(expert.firstname) ? expert.firstname : ''} {" "}   {(expert.lastname) ? expert.lastname : ''}</h1></strong><img className="verified-img m-l-5" src="images/verified_img.svg" alt="verified_img.svg" /></div>

                                                                <div className="d-flex">
                                                            <span className="expert-desg">{(expert.title) ? expert.title : ''}</span>

                                                            <div className="info-meta flag">
                                                                <span className="text-theme m-icon material-icons m-r-10">location_on</span>
                                                               
                                                                <span className="one-line text-black m-r-10"> 
                                                               <span className={`text-black ${(expert.city != null && expert.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{expert.city != null?expert.city.toLowerCase():''}</span> 
                                                                , {expert.countrycode}</span>
                                                                <ReactCountryFlag
                                                                    countryCode={expert.countrycode}
                                                                    svg

                                                                />      
                                                            </div>

                                                                     

                                                </div>
                                                {(expert.avgrating == null) ? '' :
                                                    <>     <div className="ratings m-t-0 ">
                                                        <StarRating rating={(expert.avgrating) ? expert.avgrating : 0} />
                                                        <span className="m-l-5 m-t-10">{(expert.avgrating) ?  toRoundOff (expert.avgrating) + "/5" : 0}</span>
                                                    </div></>}

                                                                </div>
                          </div>

                                                        </div>

                                            <div className="work-pay">

                                                {(expert.dailyrate) ? <>  <strong>${new Intl.NumberFormat('en-US').format(parseInt(expert.dailyrate))}</strong><span className="text-sm">Daily</span></> : ''}
                                                        </div>

                                                    </div> 
                      <div className="month-data text-light-gray">
                                    <span>Joined <strong><span className="text-black">{sinceDate(expert.datecreated, new Date())}</span></strong></span>  
                                                    </div>
                                
                                {(expert.certificates && expert.certificates.length > 0) ? <div className="certified-list" style={{width:"max-content"}}>
                                    <b>
                                        Certified To:</b> {(expert.certificates) && expert.certificates.map((data, index) => {

                                        return (<span key={index}>  {(expert.certificates.length - 1 !== index) ? data.name + ", " : data.name}</span>)
                                    })}
                                </div> : <div className="certified-list" style={{width:"max-content"}}>
                                        <b>
                                            Certified To:</b>   No Certificate available
                                        </div>}
                                        <div className="type-name">

                                            {(expert.explevel) && expert.explevel.map((data, index) => {
                                                return (<div className="info-meta" key={index}>
                                                    <span className="text-theme m-icon material-icons m-r-10">work</span><span>{(data.level) ? data.level : ''}</span>
                                                </div>)
                                            })}

                                            <div className="info-meta">
                                                <span className="text-theme m-icon material-icons m-r-10">calendar_month</span><span>{(expert.weeklyhours) ? expert.weeklyhours : ''}</span>
                                            </div>
                                        </div>

                                        <p className="text-gray three-line m-b-20">
                                            {(expert.profile) ? expert.profile.replace(/(<([^>]+)>)/ig, '').replaceAll("&nbsp", "") : ""}

                                        </p>
                                                     

 

                                <div className="expert-action">
                                    <a href={"expert/" + expert.sub} target="_self" className="btn btn-link">View More Details<span className="m-icon material-icons m-l-10">arrow_forward</span></a>

                                    <div className="action-btns">
                                         
                                        {gContext.userInfo.userID && gContext.userInfo.userType == '2' && gContext.userInfo.profileInfo && JSON.parse(gContext.userInfo.profileInfo).length > 0 && !JSON.parse(gContext.userInfo.profileInfo)[0].flagged && <button className="btn btn-orange" onClick={() => { setShowJobs(true); setExpert(expert); }}  >

                                            Invite  &nbsp;
                                        </button>}
                                        {!localStorage.getItem("connect") && <button className="btn btn-orange  rounded-10 "

                                            onClick={(e) => {
                                                e.preventDefault();
                                                gContext.toggleSignInModal();
                                            }}   >
                                            Get a FREE Quote
                                        </button>}


                                    </div>
                                </div>
                                                </div> 
                </div>



                                     


                                   



                        <div className="col-sm-12 col-lg-6 d-none " key={index}>
                            {/* <!-- Start Feature One --> */}
                            <div className="bg-white px-8 pt-3 pb-7 rounded-4 mb-9 feature-cardOne-adjustments">

                                <div className=" align-items-center row">
                                    <div className="col-2 pr-5">  
                                    <div className="  d-block   mb-5">
                                        <a href={"expert/" + expert.sub} target="blank">
                                                {(expert.avatar) && <img src={s3URL + expert.avatar} id={"image" + index} onError={(img) => { img.target.src = '../../images/user-default.png'; }} className="w-100 circle-72 "   />}
                                        </a>
                                    </div>
                                    </div>
                                    <div className="col-6 pl-7">
                                        <h3 className="mt-0 mb-0">

                                            <a className="font-size-6 heading-default-color" href={"expert/" + expert.sub} rel="noopener noreferrer" target="blank">
                                                {(expert.firstname) ? expert.firstname : ''} {" "}   {(expert.lastname) ? expert.lastname : ''}
                                            </a>

                                        </h3>

                                        <span className="font-size-4  text-default-color line-height-2 mr-2">
                                            {(expert.title) ? expert.title : ''} <br />
                                            <i className="icon icon-pin-3   mr-1 font-weight-bold"></i>
                                            <span className="font-size-3 font-weight-normal">  
                                            <span className={`text-black ${(expert.city != null && expert.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{expert.city != null?expert.city.toLowerCase():''}</span>
                                             , {expert.countrycode}  </span>
                                        </span><br />

                                        <span className="font-size-4  text-default-color mb-3  line-height-2 ">
                                            {(expert.avgrating == null) ? '' :
                                                <>
                                                    <StarRating rating={(expert.avgrating) ? expert.avgrating : 0} />
                                                    <span className=" pt-1 pl-3 font-size-3 pr-5">{(expert.avgrating) ? expert.avgrating.toString() + "/5" : 0}
                                                    </span></>}
                                        </span>
                                    </div>
                                    <div className="col-4  align-items-right text-right "> {(expert.dailyrate) ? <>
                                        <p className="font-weight-bold font-size-6 text-hit-gray mb-0 pl-1">
                                            <i className="fa-solid fa-dollar font-size-6 text-green-2"></i>
                                            <span className="text-black-2">
                                                {new Intl.NumberFormat('en-US').format(parseInt(expert.dailyrate))}</span>&nbsp;
                                            <a className="font-weight-normal font-size-3 text-hit-gray mb-0">Daily</a>
                                        </p></> : ''}
                                    </div>



                                    {(expert.datecreated) &&
                                        <div className="font-size-3 pl-5 pr-30">


                                            <ul className="list-unstyled mb-1 card-tag-list  ">
                                                <li>


                                                    <a className="bg-regent-opacity-15 text-gray font-size-3 rounded-3  ">
                                                        <i className="fa fa-clock mr-2 font-weight-bold"></i>{" "}
                                                        Joined&nbsp;{(expert.datecreated) && sinceDate(expert.datecreated, new Date())}
                                                    </a>
                                                </li>
                                            </ul>




                                        </div>}
                                    <br />
                                    <div className="col-xxl-4 col-xl-6 col-lg-6 col-md-6 ">
                                        <ul className="list-unstyled mb-1 card-tag-list  ">

                                            {(expert.explevel) && expert.explevel.map((data, index) => {

                                                return (<li key={index}>

                                                    <a className="bg-regent-opacity-15 text-green-2 font-size-3 rounded-3 ">
                                                        <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                        {(data.level) ? data.level : ''}
                                                    </a>


                                                </li>)
                                            })}
                                        </ul>
                                    </div>
                                    <div className=" col-xxl-4 col-xl-6 col-lg-6 col-md-6  mr-5">

                                        <ul className="list-unstyled mb-1 card-tag-list  ">
                                            <li>

                                                <a className="bg-regent-opacity-15 text-orange font-size-3 rounded-3  px-2">
                                                    <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                    {(expert.weeklyhours) ? expert.weeklyhours : ''}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>



                                    <div className="d-none col-xxl-4 col-xl-6 col-lg-6 col-md-6 ">
                                        <ul className="list-unstyled mb-1 card-tag-list  ">
                                            <li>
                                                <a className="bg-regent-opacity-15 text-eastern font-size-3 rounded-3">
                                                    <i className="fa fa-dollar-sign   font-weight-bold"></i>{" "}
                                                    {(expert.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(expert.dailyrate)) : (expert.ratefrom && expert.rateto) ? new Intl.NumberFormat('en-US').format(parseInt(expert.ratefrom)) + ' - ' + new Intl.NumberFormat('en-US').format(parseInt(expert.rateto)) : ''}
                                                </a>

                                            </li>
                                        </ul>
                                    </div>

                                </div>

                                <br />

                                <a href={"expert/" + expert.sub} rel="noopener noreferrer" target="blank" className=" w-100 ">
                                    <div className=" font-size-4 text-gray mb-3 w-100 " style={{ textOverflow: 'ellipsis', height: '73px', overflow: 'hidden', wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(expert.profile).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}>

                                    </div>
                                    {(DOMPurify.sanitize(expert.profile).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``).length > 150) &&
                                        <div className=" mt-n7    text-right w-100"><i className="fa-solid fa-ellipsis mr-7 mt-7 "></i> </div>}</a>

                            </div>
                            
                        </div></>
                    )
                })}
           
        </div>
            {showJobs && <ModalPostedJobs expertData={expertData} modalvisible={showJobs} toggleModal={() => { setShowJobs(!showJobs); }} />}
        </>
    );
};
