import React, { useState, useContext, useEffect } from "react";
import StarRating from "../../components/Core/Rating";
import { Popover, OverlayTrigger } from 'react-bootstrap'; 
import GlobalContext from "../../context/GlobalContext";
import ReactCountryFlag from "react-country-flag";
import { getMonthYear } from "../../utils/addDays";
import { GetExpertProfileLoad, GetExpertPreviewLoad } from "../../utils/apiCalls";
import Message from "../../components/Message";
import DOMPurify from 'isomorphic-dompurify'; 
import { Helmet } from "react-helmet";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
import { getMonthDateYear } from "../../utils/addDays";
import { sinceDate } from "../../utils/addDays";
import { daylightsSavings } from "../../utils/helperFn";
import ModalPostedJobs from "../../sections/ClientDashboard/ModalInvite";
import Feedback from "../../sections/ExpertDashboard/Feedback";
import Icon from '@mui/material/Icon';
import { parseInt } from "lodash";
var imgP = "";
 


const ProfileView = (props) => {
    {
        const gContext = useContext(GlobalContext);
        gContext.setUserMode(props.userMode);
        const subParam = props.sub; 
        const [profileInfo, setProfileInfo] = React.useState({});
        const [rating, setRating] = React.useState(null);
        const [show, setShow] = React.useState(false);
        const [message, setMessage] = React.useState(false); 
        const [showJobs, setShowJobs] = useState(false);        
        const s3URL = process.env.REACT_APP_AWS_S3_URL;         
        const [allfeedbacks, setAllFeedbacks] = useState([]);
        const [feedbacks, setFeedbacks] = useState([]);        
        const [loaded, setLoaded] = useState(false);
        const [pageCount, setPageCount] = useState(1);
        const [showMore, setShowMore] = useState(false); 
        const [expertData, setExpert] = useState({});
        const [page, setPage] = useState(1);
        const { slice, range } = tableHelper((profileInfo.contracts ? profileInfo.contracts.completed : []), page, gContext.paginationSize);
        var localtime;
        function getUSTimeZone(timezonevalue) {
           
            var sign = timezonevalue.substring(3, 4);
            sign = sign.replace("−", "-");
            sign = sign.replace("±", "-");
            var UTC_value = timezonevalue.substring(5, 6);
            var value = sign == "-" ? daylightsSavings(new Date()) - parseInt(UTC_value) : parseInt(UTC_value) + daylightsSavings(new Date());
          
            return timezonevalue.replace("UTC", "GMT").replace(timezonevalue.substring(3, 6), sign == "-" ? value : '+' + value);

        }
        function getlocaltime(offset_value) {
          
            try {
                const now = new Date();
                const timezoneOffset = now.getTimezoneOffset() / 60;

                var sign = offset_value?.substring(3, 4);

                sign = sign.replace("−", "-");
                sign = sign.replace("±", "-");

                var UTC_value = offset_value?.substring(4, 6);
                var secondpart = offset_value?.substring(7, 9);


                var UTC_minutes = UTC_value * 60;
                var total_minutes = parseInt(UTC_minutes) + parseInt(secondpart) ;

                var total_hours = (profileInfo.countrycode && profileInfo.countrycode == "US") ? (total_minutes / 60) - daylightsSavings(now) : (total_minutes / 60);
                total_hours = sign + total_hours ;

                const timezones = [total_hours];              
                
                timezones.forEach(timezone => {
                    const difference = +timezone + timezoneOffset;
                    const time = new Date(now.getTime() + difference * 3600000);
                    localtime = time?.toLocaleTimeString([], { timeStyle: 'short', hour12: true });
                    
                })

            }
            catch (e) {
                console.log(e);
            }
        }
        function roundOne(n) { 
            if ((n - Math.floor(n)) != 0) {
                var dec_value = parseFloat(n).toFixed(1);
                return dec_value;

            }
            else {
                return n;
            }

        }

        async function ExpertPreviewInfo() {
            try {
                
                setMessage(false);
                const result = (!props.userMode) ? await GetExpertProfileLoad(subParam) : await GetExpertPreviewLoad(subParam);


                if (result.data.expertAllLoad.responseData.code == "200") { 

                    let profile = JSON.parse(result.data.expertAllLoad.responseData.message);
                   
                    if (profile && profile.length > 0) {

                        if (profile[0].flagged && !props.userMode) {
                            window.location.href = "/notavailable";
                            return;
                        }
                        if (profile[0].deleted && !props.userMode) {
                            window.location.href = "/notavailable";
                            return;
                        }
                        
                        setProfileInfo(profile[0]);
                        imgP = (profile[0].avatar && profile[0].avatar != "" && profile[0].avatar.toLowerCase() != "null") ? process.env.REACT_APP_AWS_S3_URL + profile[0].avatar : "../images/user-default.png";

                        setAllFeedbacks(profile[0].feedbacks);
                        setFeedbacks(profile[0].feedbacks.slice(0, (profile[0].feedbacks.length > (pageCount * 3)) ? (pageCount * 3) : profile[0].feedbacks.length));
                        setShowMore(profile[0].feedbacks.length > (pageCount * 3)); 

                        if (profile[0].avgrating == null || profile[0].avgrating == 0) {
                            setRating(null);

                        }
                        else if (profile[0].avgrating > 0) {
                            setRating(profile[0].avgrating);
                        }

                        gContext.setLoaderModalVisible(false);
                    }
                    else {
                        if (subParam)
                            window.location.href = "/notavailable";
                        else
                            setMessage(true);
                    }
                }
                else {
                    window.location.href = "/notavailable";
                    setMessage(true);
                }
                setLoaded(true);
            }
            catch (e) {
                setMessage(true);
                console.log(e);
            }

        }
        function sociallink(link) {

            var reg = new RegExp("^(http|https)://", "i");

            var match = reg.test(link);
            //alert(link);
            if (match == true) {
                return link;
            }
            else {
                link = 'https://' + link;
                return link;
            } 

        } 

        function LoadMore() {            
            setPageCount(pageCount + 1);
        }
         
        useEffect(() => {
           
            document.title = "Profile | Syncuppro";
            ExpertPreviewInfo();

        }, []);


        useEffect(() => {  
            if (pageCount > 1) {
                let data = allfeedbacks;
                setFeedbacks(data.slice(0, (data.length > (pageCount * 3)) ? (pageCount * 3) : data.length));                
                setShowMore(data.length > (pageCount * 3));
            }

        }, [pageCount]);


        useEffect(() => {
            setShow(true);
        }, [profileInfo]);

        const popoverCompliance = (
            <Popover className="   " >

                <div className="tooltip-header bg-gray">
                    <h5>Information</h5>
                </div>
                <div className="tooltip-meta">
                    <p   >
                        A <strong>Consultant</strong> is a person who is an expert in ISO/Compliance standards and has an extensive experience in implementing the standards, following the guidelines and has worked in a variety of industries.

                                                                              <br />   A<strong> first-party audit</strong> is performed inside an organization to measure its strength and weakness against its systems or techniques or potentially against external standard principles embraced by the organization. A first-party audit is an internal audit led by auditors who are working in the organization but who have no personal stake in the audit results of the area being audited.

                                                                              <br />  A <strong> second-party audit</strong> is an external audit performed on a supplier by a client or by a contracted organization for a client. An agreement is set up, and the services and products are being or will be, conveyed. Second-party audits are dependent upon the guidelines of agreement law, as they are legally binding to the client and the supplier. Second-party audits will quite often be more formal than first-party audits since audit results could impact the client's buying choices.

                                                                             <br />   A <strong>third-party audit</strong> is performed by an audit organization independent of the client-supplier relationship and is liberated from an irreconcilable circumstance. The independence of the audit organization is a vital part of an external audit. Third-party reviews might bring about certification, enlistment, acknowledgment, an honor, license endorsement, a reference, a fine, or punishment given by the 3rd party organization.

                                                                             <br />  <strong> 1st party auditor</strong> can be an internal resource of organization  who conducts review of organizational systems.

                                                                              <br />  <strong> 2nd party auditor</strong> can be an internal or external resource conducting review of supplier /vendor systems.

                                                                              <br /> <strong>  3rd party auditor</strong> are affiliated to 3rd independent bodies to perform audit of the organizational system.

                                                                   </p>

                </div>




            </Popover>

        );
        return (
            <>  {loaded && <>
                {!(profileInfo) && message &&
                    <div className="mt-24 mt-lg-31 mr-10 ml-10  dashboard-main-container row ">
                        <Message message="No data found" />
                    </div>} {profileInfo.userInfo}
                {(show === true ) && <>
                    {profileInfo.firstname && <Helmet>
                        
                        <meta name="title" content={"SYNCUPPRO | " + profileInfo.firstname + " " + profileInfo.lastname} />
                        <meta name="description" content={profileInfo.profile?.replace(/(<([^>]+)>)/ig, '').length < 100 ? profileInfo.profile?.replace(/(<([^>]+)>)/ig, '') : profileInfo.profile?.replace(/(<([^>]+)>)/ig, '').substring(0, 100)} />
                        <meta name="og:title" content={"SYNCUPPRO | " + profileInfo.firstname + " " + profileInfo.lastname} />
                        <meta name="og:description" content={profileInfo.profile?.replace(/(<([^>]+)>)/ig, '').length < 100 ? profileInfo.profile?.replace(/(<([^>]+)>)/ig, '') : profileInfo.profile?.replace(/(<([^>]+)>)/ig, '').substring(0, 100)} />
                        <meta name="twitter:title" content={"SYNCUPPRO | " + profileInfo.firstname + " " + profileInfo.lastname} />
                        <meta name="twitter:description" content={profileInfo.profile?.replace(/(<([^>]+)>)/ig, '').length < 100 ? profileInfo.profile?.replace(/(<([^>]+)>)/ig, '') : profileInfo.profile?.replace(/(<([^>]+)>)/ig, '').substring(0, 100)} />

                    </Helmet>}
                   

                    <div className="page-wrapper">

                        <div className="section">

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-3 col-md-12 col-sm-12">

                                        <div className="card profile-details">
                                            <div className="profile-img">
                                                <img src={imgP} id="logo" onError={(img) => { img.target.src = '../images/user-default.png'; }} alt={profileInfo.firstname +" " +profileInfo.lastname} />
                                            </div>

                                            <div className="d-flex align-item-center justify-content-center">
                                                <strong className="expert-name"> {profileInfo.firstname} {profileInfo.lastname}
                                                    <img className="verified-img m-l-5" src="../images/verified_img.svg" alt="verified_img.svg" />
                                                   </strong>
                                            </div>

                                            <div className="d-flex flex-column align-item-center justify-content-center">
                                                <div className="month-data text-light-gray m-b-5">
                                                    <span>Active since <strong> <span className="text-black">{(profileInfo.datecreated) ? sinceDate(profileInfo.datecreated, new Date()).replace('ago', '') : ''}</span></strong></span>
                                                </div>
                                                <div className="ratings m-t-0 justify-content-center align-items-center">
                                                    
                                                    {(rating == null) ? '' : <>
                                                        <StarRating rating={rating} isSelectable="false" /> <span class="m-l-5 m-t-5">{roundOne(rating)}/5 </span> </>}
                                                </div>
                                                <div className="info-meta m-t-10">

                                                    <Icon className=" pointer dropdown-toggle text-theme m-icon material-icons  m-r-10 " data-bs-toggle="dropdown" aria-expanded="false">share</Icon><span className="pointer dropdown-toggle  " data-bs-toggle="dropdown" aria-expanded="false" ><b>Share</b> </span>

                                                    

                                                    <ul className="dropdown-menu dropdown-menu-end share-dropdown">
                                                        <li> <FacebookShareButton
                                                            url={window.location.href}


                                                        >
                                                            <a className="dropdown-item d-flex align-item-center" target='_blank' href={window.location.href}><img className="m-r-10" src="../images/social-icon-sm/facebook.svg" />Facebook</a>

                                                        </FacebookShareButton></li>
                                                        <li>   <TwitterShareButton title={profileInfo.firstname +" " +profileInfo.lastname} url={window.location.href}
                                                        >
                                                            <a className="dropdown-item d-flex align-item-center   " href={window.location.href}><div className=" btn    btn-circle bg-black m-r-10"><img className="  w-half " src="../images/twitter.svg" /></div>X</a>
                                                        </TwitterShareButton></li>
                                                        <li>   <LinkedinShareButton title={profileInfo.firstname +" " +profileInfo.lastname} summary={profileInfo.profile ? (profileInfo.profile.replace(/(<([^>]+)>)/ig, '').length < 100 ? profileInfo.profile.replace(/(<([^>]+)>)/ig, '') : profileInfo.profile.replace(/(<([^>]+)>)/ig, '').substring(0, 100)) : ""} url={window.location.href} >

                                                            <a className="dropdown-item d-flex align-item-center" href={window.location.href} target='_blank' ><img className="m-r-10" src="../images/social-icon-sm/linked.svg" />Linked</a>

                                                        </LinkedinShareButton></li>



                                                    </ul>


                                                </div>

                                                
                                            </div>

                                            <div className="divider-line-20"></div>

                                            <div className="meta-inline align-item-center">
                                                <span>Rate :</span>
                                                
                                                <div className="work-pay">
                                                    <span>$</span><strong>{(profileInfo.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(profileInfo.dailyrate)) : "0"} </strong> <span className="text-sm " style={{fontWeight:"bold"}}>Daily</span>
                                                </div>
                                              
                                            </div>
                                          

                                            <div className="divider-line-20"></div>

                                            <div className="meta-inline">
                                                <span>Experience :</span>
                                               
                                                 
                                                <strong> {(profileInfo.explevelname)}</strong>
                                            </div>

                                            <div className="divider-line-20"></div>

                                            <div className="meta-inline">
                                                <span>Location :</span>

                                                <div className="  info-meta flag  " data-toggle="tooltip" title={profileInfo.state+" "+profileInfo.countrycode}>
                                                    <span className="text-theme m-icon material-icons m-r-10">location_on</span>
                                                    <span className=" text-capi one-line text-black m-r-10 width-max"><strong>{profileInfo.state} {profileInfo.countrycode}</strong></span>
  
                                                    <ReactCountryFlag
                                                        countryCode={profileInfo.countrycode}
                                                        svg
                                                        style={{
                                                            width: '1.5em',
                                                            height: '.7em',
                                                        }}
                                                        title={profileInfo.countrycode}
                                                    /> 
                                                </div>
                                            </div>

                                            <div className="divider-line-20"></div>

                                            <div className="meta-inline" onLoad={getlocaltime(profileInfo.timezonevalue)}>
                                                <span>Local time zone :</span>

                                                <strong >   {localtime?.toUpperCase()} ({profileInfo.countrycode == "US" ? getUSTimeZone(profileInfo.timezonevalue) : (profileInfo.timezonevalue)?.replace('UTC', 'GMT')})</strong>
                                            </div>

                                            <div className="divider-line-20"></div>

                                            <div className="meta-inline">
                                                <span>Time zone :</span>

                                                <strong>  ({profileInfo.timezonecode}) - {profileInfo.timezonename}</strong>
                                            </div>

                                            <div className="divider-line-20"></div>

                                            <div className="meta-inline">
                                                <span>Available weekly hours :</span>

                                                <strong>{profileInfo.weeklyhours}</strong>
                                            </div>

                                            <div className="divider-line-20"></div>

                                           
                                            <div className="meta-inline">
                                                <span>Total hours worked:</span>

                                                <strong>{profileInfo.totalHours ? profileInfo.totalHours + " Hrs" : "N/A"}</strong>
                                            </div>

                                            {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' : <>  <div className="divider-line-20"></div>


                                                <div className="meta-inline ">
                                                    <span>Amount Earned :</span>
                                                    <span></span> <strong>${profileInfo.amountEarned ? new Intl.NumberFormat('en-US').format(parseInt(profileInfo.amountEarned)) : "0"}</strong>
                                                </div></>}
                                            <div className="divider-line-20  "></div> 
                                            <div className="social-icon-sm">
                                                <ul className="list-type d-flex">


                                                    {((props.mode === "client" && gContext.userInfo.profileInfo != null) || (gContext.userInfo.userType == 2) || (gContext.userInfo.sub == subParam)) &&  <>

                                                        {(profileInfo.social) ? profileInfo.social.map((item, index) => {

                                                            return ( <>

                                                                {(item.link == "Facebook") ? <li> <a key={index} href={sociallink(item.url)} rel="noopener noreferrer" target="_blank"> <img src="../images/social-icon-sm/facebook.svg" className="w-30" /></a></li> : ''}
                                                                {(item.link == "LinkedIn") ? <li> <a key={index} href={sociallink(item.url)} rel="noopener noreferrer" target="_blank"><img src="../images/social-icon-sm/linked.svg" className="w-30" /></a> </li>: ''}
                                                                {(item.link == "Twitter") ? <li><a key={index} href={sociallink(item.url)} rel="noopener noreferrer" target="_blank"><img src="../images/social-icon-sm/x-icon.svg" className="w-30"  /></a></li> : ''}
                                                                {(item.link == "YouTube") ? <li> <a key={index} href={sociallink(item.url)} rel="noopener noreferrer" target="_blank"><img src="../images/social-icon-sm/youtube.svg" className="w-30" /></a></li> : ''}
                                                                {(item.link == "Blog") ? <li><a key={index} href={sociallink(item.url)} rel="noopener noreferrer" target="_blank"><img src="../images/social-icon-sm/blogger.svg" className="w-30" /></a></li> : ''}
                                                                {(item.link == "Vlog") ? <li><a key={index} href={sociallink(item.url)} rel="noopener noreferrer" target="_blank"><img src="../images/social-icon-sm/blogger.svg" className="w-30" /></a></li> : ''}
                                                                {(item.link == "Others") ? <li><a key={index} href={sociallink(item.url)} rel="noopener noreferrer" target="_blank"><img src="../images/social-icon-sm/other.svg" className="w-30" /></a></li> : ''}

                                                           </> )
                                                        }) : ''}
                                                    </>}
                                                    
                                                    
                                                </ul>
                                            </div>

                                        </div>

                                        <div className="profile-details-btn">
                                            <button className="btn btn-green d-none"><span className="text-white m-icon material-icons m-r-10">edit</span> <span className="text-white">Edit</span></button>
                                            <button className="btn btn-dark-outline dropdown-toggle m-0 d-none" data-bs-toggle="dropdown"><span className="m-icon material-icons m-r-10">share</span> Share</button>

                                            <ul className="dropdown-menu dropdown-menu-end share-dropdown d-none">
                                                <li><a className="dropdown-item d-flex align-item-center" href="#"><img className="m-r-10" ssrc="../images/social-icon-sm/facebook.svg" />Facebook</a></li>
                                                <li><a className="dropdown-item d-flex align-item-center" href="#"><img className="m-r-10" ssrc="../images/social-icon-sm/twitter.svg" />Twitter</a></li>
                                                <li><a className="dropdown-item d-flex align-item-center" href="#"><img className="m-r-10" ssrc="../images/social-icon-sm/linked.svg" />Linked</a></li>
                                            </ul>

                                        </div>

                                    </div>

                                    <div className="col-lg-9 col-md-12 col-sm-12">
                                        <div className="card panel profile-details-desc">

                                            <div className="card-header">
                                                <ul className="nav nav-tabs tab-light">
                                                    <li className="nav-item p-relative">
                                                        <a className="nav-link active" data-bs-toggle="tab" href="#profile-profile">Profile</a>
                                                    </li>
                                                    <li className="nav-item p-relative">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#profile-jobs">Jobs</a>
                                                    </li>
                                                </ul>
                                               


                                            </div>

                                            <div className="card-body">
                                                <div className="tab-content">

                                                    <div className="tab-pane active" id="profile-profile">
                                                        <div className="profile-details-desc-title">
                                                            <div className="row">
                                                                <div className="col-lg-6 col-md-6 col-sm-12">
                                                                    <h6>{profileInfo.title}</h6>
                                                                </div>
                                                                <div className="col-lg-6 col-md-6 col-sm-12 p-b-15  d-flex justify-content-end">
                                                                    <div>{gContext.userInfo.userID && gContext.userInfo.userType == '2' && gContext.userInfo.profileInfo && JSON.parse(gContext.userInfo.profileInfo).length > 0 && !JSON.parse(gContext.userInfo.profileInfo)[0].flagged && <button className="btn btn-orange" onClick={() => { setShowJobs(true); setExpert(profileInfo); }}  >

                                                                        Invite  &nbsp;
                                                                    </button>}
                                                                        {!localStorage.getItem("connect") && <button className="btn btn-orange  rounded-10 "

                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                gContext.toggleSignInModal();
                                                                            }}   >
                                                                            Get a FREE Quote
                                                                        </button>}</div>   
                                                                </div>
                                                            </div>
                                                            
                                                            <div >
                                                            <div class="certified-list" style={{width:"max-content"}}>
                                                                <b>Certified To:</b> {profileInfo && profileInfo.exptcerts && profileInfo.exptcerts.length == 0 && <span  > No certificate found</span>}  {(profileInfo && profileInfo.exptcerts) && profileInfo.exptcerts.map((data, index) => {
                                                                    const { name } = data;
                                                                    return (
                                                                        <span key={index}  >   {(profileInfo.exptcerts.length - 1 !== index) ? name + "," : name} 
                                                                    </span>

                                                                    )
                                                                })}
                      </div>                                </div>
                                                            <p className="text-light-gray" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(profileInfo.profile) }}></p>

                                                        </div>
                                                        <div className="divider-line-20 d-none"></div>

                                                        <div className="company-meta d-flex d-none">
                                                            <h6>Certified To :{profileInfo && profileInfo.exptcerts && profileInfo.exptcerts.length == 0 && <span className="text-gray f-16"> No certificate found</span> }  {(profileInfo && profileInfo.exptcerts) && profileInfo.exptcerts.map((data, index) => {
                                                                const { name } = data;
                                                                return (
                                                                    <span key={ index} className="text-green f-16">   {(profileInfo.exptcerts.length - 1 !== index) ? name + "," : name} </span>

                                                                )
                                                            })}</h6>
                                                            
                                                        </div>

                                                        <div className="divider-line-20"></div>
                                                        <div className="company-meta">
                                                            <div className="d-flex">
                                                                <h6 className="m-r-10">Compliance standard</h6>
                                                            </div>
                                                            {(profileInfo.compliances) ? profileInfo.compliances.map((item, index) => {
                                                                return (<span key={index} className="badge compliance-standard m-t-5">  {item.code}</span>)
                                                            }) : ''}


                                                        </div>
                                                        

                                                        <div className="divider-line-20"></div>

                                                        <div className="company-meta">
                                                            <h6>Compliance category  <OverlayTrigger trigger="hover" placement="auto" overlay={popoverCompliance} ><span className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger></h6>
                                                            {(profileInfo.complianceCategory) ? profileInfo.complianceCategory.map((item, index) => {

                                                                return (
                                                                    <span key={index} className="badge compliance-category m-t-5">  {item.category}</span>
                                                                )
                                                            }) : ''}

                                                        </div>

                                                        

                                                        <div className="divider-line-20"></div>

                                                        <div className="company-meta">
                                                            <h6>Skills</h6>
                                                            {(profileInfo && profileInfo.skills) && profileInfo.skills.map((data, index) => {
                                                                const { skill } = data;
                                                                return (<span key={index} className="badge skills m-t-5"> {skill}</span>)
                                                            })}
                                                        </div>

                                                        <div className="divider-line-20"></div>
                                                        <div className="company-meta">
                                                            <h6>Industry types</h6>
                                                            {(profileInfo && profileInfo.industry) && profileInfo.industry.map((data, index) => {
                                                                const { name } = data;
                                                                return (
                                                                    <span key={index} className="badge industry-types m-t-5"> {name}</span>

                                                                )
                                                            })}

                                                        </div>
                                                        

                                                         
                                                        {(gContext.userInfo.userType == '2' && gContext.userInfo.idVerified && gContext.userInfo.profileComplete )&&
                                                            <div className="company-meta  " >
                                                            <div className="divider-line-20"></div>
                                                            <h6>Certificate</h6>
                                                            {profileInfo.exptcertsapproved && profileInfo.exptcertsapproved.length ==0 && <span>No Certificate found</span> }
                                                            {(profileInfo.exptcertsapproved) ? profileInfo.exptcertsapproved.map((item, index) => {
                                                                    return (
                                                                        <>  
                                                                            <div className="d-flex m-t-5">
                                                                                <div className="btn-circle">
                                                                                    <span className="text-theme m-icon material-icons">workspace_premium</span>
                                                                                </div>
                                                                                <div className="company-meta-inline">
                                                                                    <h5 className="m-b-10">{item.name}</h5>
                                                                                    <span className="text-light-gray m-b-5">Expiry date : {item.certexpirydate ? getMonthYear(item.certexpirydate) : "--N/A--"}</span>


                                                                                    <div className="inline-icon-info">
                                                                                        <div className="file-data m-r-20">
                                                                                            {item.filename && item.filename != "" && <><span className="text-theme m-icon material-icons m-r-10">upload_file</span>
                                                                                                <a className="text-theme" data-toggle="tooltip" title="Click here to view." href={s3URL + item.filename} target="_blank">View File</a></>}
                                                                                        </div>

                                                                                        <div className="file-data">
                                                                                            {item.url && item.url != "" && <> {item.filename && item.filename != "" && <></>} <>
                                                                                                <span className="text-theme m-icon material-icons m-r-10">link</span>
                                                                                                <a className="text-theme" href={sociallink(item.url)} target="_blank" rel="noopener noreferrer" data-toggle="tooltip" title="Click here to view.">Visit Link</a></></>}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>

                                                                            </>)
                                                                }) : ''}
                                                            </div>
                                                        }
                                                        {((props.mode === "client" && gContext.userInfo.profileInfo != null) || (gContext.userInfo.userType == 2) || (gContext.userInfo.sub == subParam) || gContext.userInfo.sub == profileInfo.sub) &&
                                                            (profileInfo.education.length > 0) ? <>
                                                        <div className="divider-line-20"></div>

                                                        <div className="company-meta">
                                                            <h6>Education</h6>

                                                                    {(profileInfo.education) ? profileInfo.education.map((item, index) => {
                                                                        return (<div className="inline-icon-info" key={ index}>
                                                                <div className="btn-circle">
                                                                                <span className="text-theme m-icon material-icons">school</span>
                                                                </div>

                                                                <div className="company-meta-inline">
                                                                                <h5 className="m-b-5">  {item.degree + ' - ' + item.major}</h5>
                                                                                <span className="text-light-gray m-b-5"> {item.school}</span>
                                                                    
                                                                                <span className="text-light-gray m-b-5"> {(item.startdate) ? getMonthYear(item.startdate) : ''}  {(item.enddate) ? ' - ' + getMonthYear(item.enddate) : ''}</span>
                                                                                <span className="text-light-gray m-b-5"><ReactCountryFlag
                                                                                    countryCode={item.countrycode}
                                                                                    svg
                                                                                    style={{
                                                                                        width: '1.5em',
                                                                                        height: '.7em',
                                                                                    }}
                                                                                    title={item.countrycode}
                                                                                />   {item.countryname}</span>
                                                                </div>

                                                                        </div>)
                                                                    }) : ''}
                                                                </div></> : ''
                                                        }

                                                        {(profileInfo.experiences.length > 0) ? <>  <div className="divider-line-20"></div>

                                                        <div className="company-meta">
                                                            <h6>Work Experience</h6> 
                                                           
                                                                {(profileInfo.experiences) ? profileInfo.experiences.map((item, index) => {
                                                                    return (

                                                                       
                                                            <div className="inline-icon-info m-b-20" key={index}>
                                                                <div className="btn-circle">
                                                                    <span className="text-theme m-icon material-icons">work</span>
                                                                </div>

                                                                <div className="company-meta-inline">
                                                                                <h5 className="m-b-5"> {item.role}</h5>
                                                                                <span className="text-light-gray m-b-5"> {item.company}</span>
                                                                                <span className="text-light-gray m-b-5"> {(item.startdate) ? getMonthYear(item.startdate) : ''}  {(item.enddate) ? ' - ' + getMonthYear(item.enddate) : '- now'} - {(item.count) ? item.count.years : 0} Years {(item.count) ? item.count.months : 0} Months</span>

                                                                                
                                                                            </div>

                                                                        </div>)
                                                                }) : ''}

                                                            
                                                        </div>
                                                        </> : ''}
                                                        {
                                                            (profileInfo.testimonials && profileInfo.testimonials.length > 0) ?<>
                                                        <div className="divider-line-20"></div>

                                                        <div className="company-meta">
                                                            <h6>Testimonials</h6>
                                                                    {(profileInfo.testimonials) ? profileInfo.testimonials.map((item, index) => {
                                                                        return (
                                                                            <div className="inline-icon-info m-b-20" key={ index}>
                                                                <div className="btn-circle">
                                                                    <span className="text-theme m-icon material-icons">star</span>
                                                                </div>
                                                                <div className="company-meta-inline">
                                                                                    <h5 className="m-b-10">{item.name}</h5>

                                                                                   

                                                                    <div className="d-flex">
                                                                        <div className="file-data m-r-20">
                                                                                            {item.filename && item.filename != "" && <><span className="text-theme m-icon material-icons m-r-10">upload_file</span> <a className="text-theme" data-toggle="tooltip" title="Click here to view." href={s3URL + item.filename} target="_blank">View File</a></>}
                                                                        </div>

                                                                        <div className="file-data">
                                                                                            {item.url && item.url != "" && <> {item.filename && item.filename != "" && <></>} <>  <span className="text-theme m-icon material-icons m-r-10">link</span> <a className="text-theme" href={sociallink(item.url)}  target="_blank" rel="noopener noreferrer" data-toggle="tooltip" title="Click here to view.">Visit Link</a></></>}
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                                        )
                                                                    }) : ''}
                                                           
                                                                </div></> : ''
                                                        }

                                                        {<Feedback jobsList={feedbacks} />}
                                                        {(showMore) && <div className="text-center ">



<div className="load-more mt-5">
    <button type="button" className="btn btn-orange-outline d-flex align-item-center" onClick={() => { LoadMore(); }}>
        <Icon className="m-icon material-icons m-r-10  ">refresh</Icon>Load more</button>
</div>

</div>}
                                                        </div>

                                                    <div className="tab-pane" id="profile-jobs">
                                                        <div className="profile-jobs-block">

                                                            <div className="d-flex">

                                                                {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' : <>  <div className="col-lg-3 col-md-3 col-sm-6">
                                                                    <div className="icon-wrapper">
                                                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                                                        <div className="d-flex flex-column">
                                                                            <span className="m-b-10 text-light-gray">Active Jobs</span>
                                                                            <b> {(profileInfo && profileInfo.contracts) ? profileInfo.contracts.active.length == 0 ? '-' : profileInfo.contracts.active.length : '-'}</b>
                                                                        </div>
                                                                    </div>
                                                                </div></>}

                                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                                    <div className="icon-wrapper">
                                                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                                                        <div className="d-flex flex-column">
                                                                            <span className="m-b-10 text-light-gray">Completed Jobs</span>
                                                                            <b>{(profileInfo && profileInfo.contracts) ? profileInfo.contracts.completed.length == 0 ? '-' : profileInfo.contracts.completed.length : '-'}</b>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="accordion" id="accordionjobs">

                                                                {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' :
                                                                    ((profileInfo.contracts && profileInfo.contracts.active.length > 0) ?
                                                                        <>
                                                                <div className="accordion-wrapper m-b-30">

                                                                                <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-active-job" aria-expanded="false"><h5>Active Jobs ({(profileInfo.contracts) ? profileInfo.contracts.active.length : 0})</h5>
                                                                        <span className="m-icon material-icons indicator show">expand_more</span>
                                                                        <span className="m-icon material-icons indicator hide">expand_less</span>
                                                                    </a>

                                                                    <div id="accordion-active-job" className="collapse" data-bs-parent="#accordionjobs">
                                                                        <div className="accordion-body card panel p-0">

                                                                            <div className="card-body">
                                                                                <div className="tab-content">
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-striped">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                                <th className="min-lg-cell">Title</th>
                                                                                                                <th className="min-lg-cell" >Type</th>
                                                                                                                <th className="min-lg-cell" >Company</th>
                                                                                                                <th className="min-lg-cell" >Duration</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                                        <tbody>
                                                                                                            {(profileInfo && profileInfo.contracts) && profileInfo.contracts.active.map((data, index) => {
                                                                                                                
                                                                                                                const { companyname, jobtype, name, duration, startdate, clientsub, clientlogo, guid} = data;
                                                                                                                return (
                                                                                                                    <tr key={ index}>
                                                                                                    
                                                                                                    <td>
                                                                                                                    <span className="d-flex justify-content-left align-items-left">
                                                                                                                        <span className="title one-line pointer  one-line text-black "  data-toggle="tooltip" title={name} 
                                                                                                                         style={{ fontSize: "14px" }}
                                                                                                                                    onClick={(e) => { window.location.href = "/job/" + guid; }}>{name}</span>
                                                                                                                         
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                    
                                                                                                    <td className="d-none">
                                                                                                                            <div className="d-flex justify-content-left align-items-left">
                                                                                                                                <div className="info-meta company pointer" onClick={(e) => { window.location.href = "/job/" + guid; }}>

                                                                                                                                    <span className="one-line text-black ">{name}  </span>   
                                                                                                                                </div>

                                                                                                                             
                                                                                                                            </div>
                                                                                                                         
                                          </td>
                                          <td className="text-capi ">
                                                                                                            {jobtype?(jobtype).toLowerCase():''}</td>
                                                                                                                        <td  >
                                                                                                                            
                                                                                                                                <div className="d-flex justify-content-center align-items-center">
                                                                                                                                <div className="info-meta company pointer" onClick={(e) => { window.location.href = "/client/" + clientsub; }}   >
                                                                                                                                    <img className="m-r-10  " style={{ width: "28px" }} src={s3URL + clientlogo} alt={""} id={"image" + index} onError={(img) => { img.target.src = '../images/user-default.png'; }} />
                                                                                                                                    <span className="one-line text-black ">{companyname} </span>
                                                                                                                                    
                                              </div>

                                                                                                                             
                                                                                                            </div>
                                          </td>
                                                                                                        <td>
                                                                                                                            {(startdate) ? getMonthDateYear(startdate) + ' - Present' : ''}
                                          </td>
                                        </tr>
   )
                                                                                                   })}
                  
                                      </tbody>
                                    </table>
                                  </div>  
                                </div> 
                              </div> 

                            </div>
                                                                                </div>
                                                                            </div> </> : '')}

                                                                {(profileInfo.contracts && profileInfo.contracts.completed.length > 0) ?
                                                                    <div className="accordion-wrapper">

                                                                        <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-completed-jobs" aria-expanded="false"><h5>Completed Jobs ({(profileInfo.contracts) ? profileInfo.contracts.completed.length : 0})</h5>
                                                                                    <span className="m-icon material-icons indicator show">expand_more</span>
                                                                                    <span className="m-icon material-icons indicator hide">expand_less</span>
                                                                                </a>

                                                                                <div id="accordion-completed-jobs" className="collapse" data-bs-parent="#accordionjobs">
                                                                                    <div className="accordion-body card panel p-0">

                                                                                        <div className="card-body">
                                                                                            <div className="tab-content">
                                                                                                <div className="table-responsive">
                                                                                                    <table className="table table-striped">
                                                                                                        <thead>
                                                                                                            <tr>
                                                                                                        <th className="min-lg-cell">Title</th>
                                                                                                        <th className="min-lg-cell" >Type</th>
                                                                                                        <th className="min-lg-cell">Company</th>
                                                                                                        <th className="min-md-cell"  >Duration</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                <tbody>
                                                                                                    {(profileInfo && profileInfo.contracts) && slice.map((data, index) => {
                                                                                                        const { companyname, guid, jobtype, name, duration, clientsub, clientlogo } = data;
                                                                                                        return (
                                                                                                            <tr key={index}>
                                                                                                               
                                                                                                               <td>
                                                                                                               
                                                                                                               <span className="d-flex justify-content-left align-items-left">
                                                                                                                        <span className="title one-line pointer  one-line text-black font-size-1 font-weight-normal"  data-toggle="tooltip" title={name} 
                                                                                                                         style={{ fontSize: "14px" }}
                                                                                                                            onClick={(e) => { window.location.href = "/job/" + guid; }}>{name}</span>
                                                                                                                        
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                               
                                                                                                               
                                                                                                               
                                                                                                               
                                                                                                                <td className="d-none">
                                                                                                                    <div className="d-flex justify-content-left align-items-left">
                                                                                                                        <div className="info-meta company pointer" onClick={(e) => { window.location.href = "/job/" + guid; }}>

                                                                                                                            <span className="one-line text-black ">{name} </span>  
                                                                                                                        </div>

                                                                                                                       
                                                                                                                    </div>
                                          </td>
                                          <td className="text-capi ">
                                                                                                            {jobtype?(jobtype).toLowerCase():''}
                                          </td>
                                                                                                                <td>
                                                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                                                        <div className="info-meta company pointer" onClick={(e) => { window.location.href = "/client/" + clientsub; }}   >
                                                                                                                            <img className="m-r-10  " id={"image" + index} onError={(img) => { img.target.src = '../images/user-default.png'; }} style={{ width: "28px" }} src={s3URL + clientlogo} alt={""} />
                                                                                                                            <span className="one-line text-black ">{companyname}</span> 
                                                                                                                        </div>

                                                                                                                        
                                                                                                                    </div>
                                          </td>
                                                                                                                    <td>
                                                                                                                    {(duration.years <= 0) ? '' : duration.years + ' Years'}
                                                                                                                    {(duration.months <= 0) ? '' : duration.months + ' Months'} 
                                          </td>
                                                                                                            </tr>)
                                                                                                    })}

                                                                                                               
                  
                                      </tbody>
                                                                                            </table>
                                                                                            
                                  </div> 
                                                                                        {(loaded && profileInfo.contracts && profileInfo.contracts.completed.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}
                                </div> 
                              </div> 

                            </div>
                                                                                            </div>
                                                                </div> : ''}


                      </div>

                                                                                </div>
                                                                            </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                      

                         </>
                }



                </>}



          
                {!loaded &&

                    <>
                        <div className="page-wrapper">

                            <div className="section">

                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-12 col-sm-12">

                                            <div className="card profile-details">
                                                <div className="profile-img">
                                                    <img className="movingLoader btn-circle w-150" />
                                                </div>



                                                <div className="d-flex flex-column align-item-center justify-content-center">
                                                    <div className="month-data text-light-gray text-center m-b-0 p-5 movingLoader w-150">
                                                        <span>  &nbsp; </span>
                                                    </div>

                                                    <h4 className="client-name movingLoader w-100 m-t-10 align-item-center justify-content-center  "> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>

                                                    <span className="company-tag movingLoader align-item-center justify-content-center w-100">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                                </div>
                                                <div className="divider-line-20"></div>

                                                <div className="meta-inline movingLoader w-full">
                                                    <span>&nbsp;</span>

                                                </div>


                                                <div className="divider-line-20"></div>
                                                <div className="meta-inline movingLoader w-full">
                                                    <span>&nbsp;</span>



                                                </div>


                                            </div>

                                        </div>

                                        <div className="col-lg-9 col-md-12 col-sm-12">
                                            <div className="card panel profile-details-desc">

                                                <div className="card-header">
                                                    <ul className="nav nav-tabs tab-light">
                                                        <li className="nav-item p-relative movingLoader w-100">
                                                            <a className="nav-link active movingLoader w-100" data-bs-toggle="tab" href="#company">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </a>
                                                        </li>
                                                        <li className="nav-item p-relative">
                                                            <a className="nav-link movingLoader w-100" data-bs-toggle="tab" > &nbsp;&nbsp;&nbsp;</a>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="card-body">
                                                    <div className="tab-content">

                                                        <div className="tab-pane active w-full" id="company">
                                                            <div className="profile-details-desc-title movingLoader w-full">

                                                                <h6 className=" movingLoader w-100">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h6>

                                                                <div className="d-flex justify-content-between m-b-0 w-full  movingLoader">
                                                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>
                                                                    <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>
                                                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>
                                                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>

                                                                </div>


                                                            </div>

                                                            <div className="divider-line-20"></div>

                                                            <div className="company-meta">
                                                                <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                                                    <h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>
                                                                </div>

                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>


                                                            </div>
                                                            <div className="company-meta">
                                                                <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                                                    <h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>
                                                                </div>

                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>


                                                            </div>
                                                            <div className="company-meta">
                                                                <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                                                    <h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>
                                                                </div>

                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>


                                                            </div>

                                                        </div>



                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>

                   
                    </>
                } 
                <ModalPostedJobs expertData={expertData} modalvisible={showJobs} toggleModal={() => { setShowJobs(!showJobs); }} />
            </>
        );
    }
};
export default ProfileView;