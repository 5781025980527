import React, { useState, useContext, useEffect, useRef } from "react";
import {  createDirName, ExpertFileUpload } from "../../utils/fileUpload";
import GlobalContext from "../../context/GlobalContext";
import { getMonthDateYear } from "../../utils/addDays";
import { LoadMessages, SendMessage,DeleteMessage,UpdateMessage } from "../../utils/apiCalls";
import validator from 'validator'; 
import ReactCountryFlag from "react-country-flag"; 
const imgLoader = "../assets/image/svg/spinning-circles.svg";
const imgP = "../assets/image/avatar.png";

const UserMessage = (props) => {
    const gContext = useContext(GlobalContext); 
    const [messageList, setMessageList] = React.useState([]);
    const [values, setValues] = useState({ messageId:null,message: "", resetAttachmentName: '', attachmentName: '', attachment: null, error: { message: "" } });
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [errorMessage, setErrorMessage] = React.useState('');   
    const inputRef = useRef();
    const [loader, setLoader] = useState(false);
    const [loaded, setLoaded] = useState(false); 
    const [mode, setMode] = useState("Add");
  
    let previousDate = "";
    let currentDate = "";

    function minutesDiff(dateTimeValue2, dateTimeValue1) {
        
        var differenceValue = (new Date(dateTimeValue2.toString()).getTime() - new Date(dateTimeValue1.toString()).getTime()) / 1000;
        differenceValue /= 60;
        return Math.abs(Math.round(differenceValue));
    }

    function checkValidity() {
        let isValid = false;
        let { error, ...rest } = values;

        error.message = (rest.message.trim() == "") ? "Message is required" : (isEmailContain(rest.message)?.length>0 ||validator.isEmail(rest.message) || isHtml(rest.message) || validator.isURL(rest.message)) ? "Invalid Message" : "";
        setErrorMessage(error.message);
        setValues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }
    useEffect(() => {
        if (errorMessage != "") {
           
            gContext.setErrorModal(errorMessage);
            setErrorMessage("");
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    function isHtml(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }
    function isEmailContain(input) { 
        return input.match(/\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g);
    }
    const handleFieldsChange = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        switch (name) {
            case "message":
                error.message = validator.isEmpty(value) ? "Message is required" : ( isHtml(value) || validator.isURL(value)) ?"Invalid Message" : "";
              
                break;
            default:
                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;
        }
        setValues({ ...values, error, [name]: value });
    }
   
   
    async function deleteMessage(data) {
        gContext.setLoaderModalVisible(false);
        try {
            if (gContext.userInfo.userID) {
                const result = await DeleteMessage(data.id);
                
               
                if (result.data.deleteMessage.responseData.code == "200") {
                    gContext.setLoaderModalVisible(true);  
                    BindMessage(props.fromUserID, props.fromTypeId);                   
                    
                }             
                gContext.setLoaderModalVisible(false);
               }

        }
        catch (e) {
            console.log(e); gContext.setLoaderModalVisible(false);
        }
    }


    async function updateMessage() {
        gContext.setLoaderModalVisible(false); 
       
        try {
           
            
            if (gContext.userInfo.userID && checkValidity() ) { 
                
                setLoader(true);
                 
               let attachmentName = "";
                if (values.attachment) { 
                    ExpertFileUpload(values.attachment, "messages");
                    attachmentName = createDirName() + "/messages/" + values.attachment.name;
                }                
               
                const result = await UpdateMessage(values.messageId,values.message,attachmentName); 
                
                if (result.data.updateMessage.responseData.code == "200") {
                    gContext.setLoaderModalVisible(true);   
                    BindMessage(props.fromUserID, props.fromTypeId); 
                    setMode("Add");                   
                }  
                
                gContext.setLoaderModalVisible(false);
                setLoader(false);
                
            }
            setMode("Add");

        }
        catch (e) {
         
            setMode("Add");
            setLoader(false);
            console.log(e); gContext.setLoaderModalVisible(false);
        }
    }

   

   function sinceDate    (d1)
    {
        let diffInMs; let seconds; let date1; let date2; let posteddate; let minutes; let hours; let days; let month; let year; let yeardiff;
        
        date1 = new Date(d1);
        date2 = new Date(Date.now());
        diffInMs = Math.abs(date2 - date1);
        seconds = Math.round(diffInMs / 1000);
        minutes = Math.round(diffInMs / (1000 * 60));

        return minutes;

    }
   
   
    function checkMode()
    {

        if(mode == "Edit")
        {
        
      
            updateMessage();
        }
       else if(mode == "Add")
       {
    
        SendNewMessage() ;
       }

   } 
   
    async function SendNewMessage() {
        try {
            
              setMode("Add");
            if (checkValidity()) {
                
                setLoader(true);
                
                let attachmentName = "";
                if (values.attachment) {
                     
                    const timestamp = new Date().valueOf(); 
                    ExpertFileUpload(values.attachment, "messages");
                    attachmentName = createDirName() + "/messages/" + values.attachment.name;
                }
                const result = await SendMessage({ attachment: attachmentName, email: gContext.userInfo.email, fromTypeId: gContext.userInfo.userType, fromUserId: gContext.userInfo.userID, message: values.message, toTypeId: props.fromTypeId, toUserId: props.fromUserID });
                
                if (result.data.sendMessage.responseData.code == "200") {
                    
                    BindMessage(props.fromUserID, props.fromTypeId);
                }


            }
            setLoader(false);
        }
        catch (e) {
            
            setLoader(false);
            console.log(e);
        }
    }

    
    async function BindMessage(fromUserID, fromTypeId) {
        try {
            setLoaded(false); 
            const result = await LoadMessages({ fromTypeId: fromTypeId, fromUserId: fromUserID, toTypeId: gContext.userInfo.userType, toUserId:   gContext.userInfo.userID});

            if (result.data.loadMessages.responseData.code == "200") {

                let data = JSON.parse(result.data.loadMessages.responseData.message);
                
                if (data.length > 0) {
                    setMessageList(data);
                    if (inputRef && inputRef.current)
                        inputRef.current.scrollIntoView({ behavior: "smooth", block: "end" }); 
                }
                else
                    setMessageList([]);
            }
            setValues({ ...values, ["message"]: "", ["attachment"]: null, ["attachmentName"]: "" });


            setTimeout(() => {
                setLoaded(true); 
            }, 2000);

        }
        catch (e) {
           
            setLoaded(true);
            console.log(e);
        }
    } 
   
    
    function editMessage(data)
    {
        setMode("Edit");
        if (data.attachment == '') { 
            setValues({ ...values, ["message"]: data.message.replaceAll('<br />', "\n"),["messageId"]: data.id,["attachment"]: null,["attachmentName"]: "" });
        }
        else{   
            setValues({ ...values, ["message"]: data.message.replaceAll('<br />',  "\n"),["messageId"]: data.id,["attachment"]: data.attachment  });
        }
     
    }
      

    const onFileChange = (event) => {
        if (event.target.files.length > 0) {

            setValues({ ...values, ["attachment"]: event.target.files[0] });
        }
        event.target.value = null;
    }

    useEffect(() => {
        setLoaded(false);
        setMode("Add");         
    }, [gContext.msgCount]);    

    useEffect(() => { 
        BindMessage(props.fromUserID, props.fromTypeId);
    }, [props.fromUserID, gContext.msgCount]);

   

    useEffect(() => {
        document.title = "Message | Syncuppro";
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (inputRef && inputRef.current)
                inputRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
        }, 2000);
      
    }, [messageList]);

    return (
        <> 

            {loaded &&
                <>

                <div className={`message-chat ${props.showSideMenu ? 'hide' :'show'} `}>

                        <div className="chat-header">

                            <div className="d-flex align-items-center">

                                <div className="back-arrow">
                                <button type="button" className="btn btn-icon" onClick={() => { props.setShowSideMenu(); } }>
                                        <span className="text-theme m-icon material-icons">arrow_back</span>
                                    </button>
                                </div>
                            {props.currentSender && gContext.userInfo.userType==2  && <> <div className="contact-img">
                                {(props.currentSender.avatar) ? <img src={s3URL + props.currentSender.avatar} alt="" id={"imagelogo"} onError={(img) => { img.target.src = '../../images/user-default.png'; }} /> : <img src="../images/user-default.png" alt="" />}
                                <span className="online_icon"></span>
                            </div>

                                <div className="contact-meta">
                                    <span className="contact-name one-line">{(props.currentSender.firstname) ? props.currentSender.firstname : ''}  {(props.currentSender.lastname) ? props.currentSender.lastname : ''}</span>
                                    <div className="info-meta flag">
                                        <span className="inline-meta m-r-10 one-line"><span className="text-capi">{props.currentSender.state}</span>, {props.currentSender.code}</span>
                                        <ReactCountryFlag
                                            countryCode={props.currentSender.code}
                                            svg

                                        />
                                    </div>
                                </div></>}
                            {props.currentSender && gContext.userInfo.userType == 1 && <> <div className="contact-img">
                                {(props.currentSender.logo) ? <img src={s3URL + props.currentSender.logo} alt="" id={"imagelogo"} onError={(img) => { img.target.src = '../../images/user-default.png'; }} /> : <img src="../images/user-default.png" alt="" />}
                                <span className="online_icon"></span>
                            </div>

                                <div className="contact-meta">
                                    <span className="contact-name one-line">{(props.currentSender && props.currentSender.company) ? props.currentSender.company : ''}  </span>
                                    <div className="info-meta flag">
                                        <span className="inline-meta m-r-10 one-line"><span className="text-capi">{props.currentSender.state}</span>, {props.currentSender.code}</span>
                                        <ReactCountryFlag
                                            countryCode={props.currentSender.code}
                                            svg

                                        />
                                    </div>
                                </div></>}
                               
                            </div>

                            <div className="d-flex align-item-center d-none">
                                <div className="search-info m-0">
                                    <span className="text-theme input-m-icon material-icons">search</span>
                                    <input className="form-control" type="text" placeholder="Search messages" />
                                </div>

                                <button className="btn btn-icon m-l-10"><span className="text-yellow m-icon material-icons">star</span></button>
                            </div>

                        </div>

                    <ul className="chat-history">
                        {messageList && messageList.map((data, index) => {
                            previousDate = currentDate;
                            currentDate = (data.datecreated) ? (currentDate != getMonthDateYear(data.datecreated)) ? getMonthDateYear(data.datecreated) : currentDate : "";
                            let displaydate = new Date(data.datecreated);
                            return (<>
                                {(previousDate != currentDate) && <li>
                                    <div className="date-divider">
                                        <span>{(data.datecreated) ? getMonthDateYear(data.datecreated) : ""}</span>
                                    </div>
                                </li> }

                                <li ref={(messageList.length == index + 1) ? inputRef : null} >

                                    {gContext.userInfo.userID == data.senderid && <>
                                        <div className={`chat-body    me `} >
                                           

                                           

                                                <div className="chat-info">
                                                    {gContext.userInfo.userType == 2 && <span className="chat-name">{gContext.userInfo.userID == data.senderid ? "" : ((props.currentSender.firstname) ? props.currentSender.firstname : '' + " " + (props.currentSender.lastname) ? props.currentSender.lastname : '')} </span>}
                                                    {gContext.userInfo.userType == 1 && <span className="chat-name">{gContext.userInfo.userID == data.senderid ? "" : ((props.currentSender && props.currentSender.company) ? props.currentSender.company : '')} </span>}

                                                    <span> {(displaydate) ? ((displaydate.getHours().toString().length == 1) ? "0" : "") + ((displaydate.getHours() > 12) ? displaydate.getHours() - 12 : displaydate.getHours()) + ":" + ((displaydate.getMinutes().toString().length == 1) ? "0" : "") + displaydate.getMinutes() + ((displaydate.getHours() >= 12) ? " PM" : " AM") : ""}</span>
                                            </div>

                                            {(data.attachment && data.attachment != "") ? <>  <div className="chat-bubble" style={{ minWidth: "270px" }}>
                                                <p>{data.message && data.message.split('<br />').map((data, index) => { return (<>{data}<br /></ >) })}</p>
                                                <div className="dropdown msg-action">
                                                    <button type="button"

                                                        className={`btn-icon dropdown-toggle   ${minutesDiff(new Date(), data.datecreated) <= 5 ? '' : ' d-none'} `}
                                                        data-bs-toggle="dropdown"  >
                                                        <span className="m-icon material-icons">expand_more</span>
                                                    </button>

                                                    <ul className="dropdown-menu dropdown-menu-end">
                                                        <li><a className="dropdown-item" href="#" onClick={() => { editMessage(data); }}><span className="m-icon material-icons">edit</span> Edit</a></li>
                                                        <li><a className="dropdown-item" href="#" onClick={() => { deleteMessage(data); }}><span className="m-icon material-icons">delete</span> Delete</a></li>
                                                    </ul>

                                                </div>
                                                {(data.attachment && data.attachment != "") && <>
                                                    <div className="file-block-wrap m-l-0"   >
                                                        <div className="file-block" style={{ minWidth: "100%" }}>
                                                            <div className="d-flex">
                                                                <div className="file-img  pointer" onClick={(e) => { window.open(s3URL + data.attachment, "_blank") }}  >
                                                                    <img src="../images/file.png" alt="file-img" />
                                                                </div>
                                                                <div className="d-flex flex-column pointer m-r-10" onClick={(e) => { window.open(s3URL + data.attachment, "_blank") }}  >
                                                                    <span className="chat-file-name text-light-gray m-b-5">{data.attachment.split('/').pop()}</span>
                                                                    <span className="file-text-sm m-r-10"> </span>
                                                                </div>
                                                            </div>

                                                            <div className="file-action-btn">
                                                                <a className="btn btn-link" href={s3URL + data.attachment}><span className="text-light-gray m-icon material-icons">download</span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}
                                            </div>
                                            </> : <>  <div className="chat-bubble" style={{ minWidth: "270px" }}>
                                                <p>{data.message && data.message.split('<br />').map((data, index) => { return (<>{data}<br /></ >) })}</p>
                                                    <div className="dropdown msg-action">
                                                        <button type="button"

                                                            className={`btn-icon dropdown-toggle   ${minutesDiff(new Date(), data.datecreated) <= 5 ? '' : ' d-none'} `}
                                                            data-bs-toggle="dropdown"  >
                                                            <span className="m-icon material-icons">expand_more</span>
                                                        </button>

                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                            <li><a className="dropdown-item" href="#" onClick={() => { editMessage(data); }}><span className="m-icon material-icons">edit</span> Edit</a></li>
                                                            <li><a className="dropdown-item" href="#" onClick={() => { deleteMessage(data); }}><span className="m-icon material-icons">delete</span> Delete</a></li>
                                                        </ul>

                                                    </div>
                                            </div>
                                                </>}
                                                <div className="chat-bubble d-none">
                                                    <p>{data.message && data.message.split('<br />').map((data, index) => { return (<>{data}<br /></ >) })}</p>
                                                    <div className="dropdown msg-action">
                                                        <button type="button"

                                                            className={`btn-icon dropdown-toggle   ${minutesDiff(new Date(), data.datecreated) <= 5 ? '' : ' d-none'} `}
                                                            data-bs-toggle="dropdown"  >
                                                            <span className="m-icon material-icons">expand_more</span>
                                                    </button>
                                                   
                                                        <ul className="dropdown-menu dropdown-menu-end">
                                                        <li><a className="dropdown-item" href="#" onClick={() => { editMessage(data); }}><span className="m-icon material-icons">edit</span> Edit</a></li>
                                                        <li><a className="dropdown-item" href="#" onClick={() => { deleteMessage(data); }}><span className="m-icon material-icons">delete</span> Delete</a></li>
                                                        </ul>

                                                </div>



                                                {(data.attachment && data.attachment != "") && <>
                                                    <div className="file-block-wrap">
                                                        <div className="file-block">
                                                            <div className="d-flex">
                                                                <div className="file-img  pointer" onClick={(e) => { window.open(s3URL + data.attachment, "_blank") }}  >
                                                                    <img src="../images/file.png" alt="file-img" />
                                                                </div>
                                                                <div className="d-flex flex-column  pointer" onClick={(e) => { window.open(s3URL + data.attachment, "_blank") }}  >
                                                                    <span className="chat-file-name text-light-gray m-b-5">{data.attachment.split('/').pop()}</span>
                                                                    <span className="file-text-sm"> </span>
                                                                </div>
                                                            </div>

                                                            <div className="file-action-btn">
                                                                <a className="btn btn-link"   href={s3URL + data.attachment}><span className="text-light-gray m-icon material-icons">download</span></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>}

                                                </div>



                                            </div>

                                         

                                         

                                        <div className="chat-body me">
                                            <div className="contact-img-sm">
                                                {gContext.userInfo.userType == "1" && gContext.userInfo.userID == data.senderid &&
                                                    <img src={(data.logo) ? s3URL + data.logo : imgP} alt="" id={"imagelogo"} onError={(img) => { img.target.src = '../../images/user-default.png'; }} />
                                                }
                                                {gContext.userInfo.userType == "2" && gContext.userInfo.userID == data.senderid &&

                                                    <img src={(data.avatar) ? s3URL + data.avatar : imgP} id={"imagelogo"} onError={(img) => { img.target.src = '../../images/user-default.png'; }} />

                                                }
                                            </div>
                                        </div>
                                    </>}

                                    {gContext.userInfo.userID != data.senderid && <>
                                        <div className={`chat-body    other `} >
                                            <div className="contact-img">
                                                {gContext.userInfo.userType == "1" && gContext.userInfo.userID != data.senderid &&
                                                    <img src={(data.logo) ? s3URL + data.logo : imgP} alt="" id={"imagelogo"} onError={(img) => { img.target.src = '../../images/user-default.png'; }} />
                                                }
                                                {gContext.userInfo.userType == "2" && gContext.userInfo.userID != data.senderid &&
                                                    <img src={(data.avatar) ? s3URL + data.avatar : imgP} alt="" id={"imagelogo"} onError={(img) => { img.target.src = '../../images/user-default.png'; }} />
                                                }


                                            </div>

                                            <div className="d-flex flex-column  ">

                                                <div className="chat-info ">
                                                    
                                                    {gContext.userInfo.userType == 2 && <span className="chat-name">{gContext.userInfo.userID == data.senderid ? "" : ((props.currentSender.firstname) ? props.currentSender.firstname + " " + props.currentSender?.lastname : '')} </span>}
                                                    {gContext.userInfo.userType == 1 && <span className="chat-name">{gContext.userInfo.userID == data.senderid ? "" : ((props.currentSender && props.currentSender.company) ? props.currentSender.company : '')} </span>}

                                                    <span> {(displaydate) ? ((displaydate.getHours().toString().length == 1) ? "0" : "") + ((displaydate.getHours() > 12) ? displaydate.getHours() - 12 : displaydate.getHours()) + ":" + ((displaydate.getMinutes().toString().length == 1) ? "0" : "") + displaydate.getMinutes() + ((displaydate.getHours() >= 12) ? " PM" : " AM") : ""}</span>
                                                </div>

                                                {(data.attachment && data.attachment != "") ? <>  <div className="chat-bubble" style={{ minWidth: "270px" }}>
                                                    <p>{data.message && data.message.split('<br />').map((data, index) => { return (<>{data}<br /></ >) })}</p>
                                                    {(data.attachment && data.attachment != "") && <>
                                                        <div className="file-block-wrap m-l-0"  >
                                                            <div className="file-block" style={{ minWidth: "100%" }}>
                                                                <div className="d-flex">
                                                                    <div className="file-img pointer" onClick={(e) => { window.open(s3URL + data.attachment, "_blank") }} >
                                                                        <img src="../images/file.png" alt="file-img" />
                                                                    </div>
                                                                    <div className="d-flex flex-column pointer" onClick={(e) => { window.open(s3URL + data.attachment, "_blank") }} >
                                                                        <span className="chat-file-name text-light-gray m-b-5 pointer"   >{data.attachment.split('/').pop()}</span>
                                                                        <span className="file-text-sm"> </span>
                                                                    </div>
                                                                </div>

                                                                <div className="file-action-btn">
                                                                    <a className="btn btn-link" href={s3URL + data.attachment}><span className="text-light-gray m-icon material-icons">download</span></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>}
                                                </div>
                                                </> : <>  <div className="chat-bubble " style={{ minWidth: "270px" }}>
                                                    <p>{data.message && data.message.split('<br />').map((data, index) => { return (<>{data}<br /></ >) })}</p>
                                                    
                                                </div>
                                                    </>}
                                            </div>

                                        </div>

                                        <div className={`chat-body    other d-none `} >
                                            {(data.attachment && data.attachment != "") && <>
                                                <div className="file-block">

                                                    <div className="d-flex">
                                                        <div className="file-img pointer" onClick={(e) => { window.open(s3URL + data.attachment, "_blank") }} >
                                                            <img src="../images/file-img.svg" alt="file-img" />
                                                        </div>
                                                        <div className="d-flex flex-column pointer" onClick={(e) => { window.open(s3URL + data.attachment, "_blank") }} >
                                                            <span className="chat-file-name text-light-gray m-b-5  "> {data.attachment.split('/').pop()}</span>
                                                            <span className="file-text-sm"> </span>
                                                        </div>
                                                    </div>


                                                    <div className="file-action-btn">
                                                        <a target="_blank" className="btn btn-link" href={s3URL + data.attachment}><span className="text-light-gray m-icon material-icons">download</span></a>
                                                    </div>
                                                </div></>}
                                        </div>

                                     
                                    </>}
                                    
                                </li>

                            </>)

                        })

                        }

                         

                        </ul>

                        <div className="chat-area-lg ">
                            <div className="chat-input">
                                <div className="chat-right-icon">
                                    <div className="file-input">
                                    <input
                                        type="file"
                                        id="fileUpload" accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                        className="sr-only"
                                        onChange={onFileChange}
                                    />
                                    {values.attachment === null && 
                                    <label htmlFor="fileUpload" className="btn btn-icon"><span className="m-icon right-icon material-icons">attach_file</span></label>}
                                    {values.attachment !== null &&

                                        <div className="selected-file">
                                        <span className="btn btn-circle sm light" data-bs-toggle="tooltip" title={values.attachmentName}>
                                                <span className="text-gray m-icon material-icons">insert_drive_file</span>
                                            </span>
                                        <div className="btn-icon-close" data-bs-toggle="tooltip" onClick={() => { setValues({ ...values, ["attachment"]: null, ["attachmentName"]: "" }) }} data-bs-placement="top" title="Click here to remove file">
                                                <span className="m-icon material-icons">close</span>
                                            </div>
                                        </div>
                                       }
                                    </div>
                            </div>
                            

                            <input
                                type="file"
                                id="fileUpload" accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                className="sr-only"
                                onChange={onFileChange}
                            />
                            <textarea name="message"
                                id="message" maxLength="500"
                                defaultValue={values.message} value={values.message}
                             
                                
                                onKeyUp={handleFieldsChange} onChange={handleFieldsChange} className="form-control" rows="1" placeholder="Type a message.....">

                                </textarea>
                                <span className="lable d-none">Press Shift+Enter to break line</span>
                            </div>

                            <div className="chat-action-btn">
                            {!loader && <button className="btn btn-circle m-l-20 bg-orange" onClick={() => { checkMode(); }}>

                                <span className="text-white m-icon material-icons" >send</span>
                            </button>}
                            {loader && <button className="btn btn-circle m-l-20 bg-orange" >

                                <img src={imgLoader} className="w-20" />
                            </button>}
                            </div>
                       
                        </div>
                    <span className="text-red m-l-30 m-t-0">*  ( Max 500 Character)</span>  <br className="d-block d-sm-none" />
                    <span className="  text-green  m-l-30  "> Remaining Character length: {500 - ((values.message.length) ? values.message.length : 0)}</span>
                    </div>
                </>}
            {!loaded &&
                <>
                <div className="message-chat show">

                    <div className="chat-header">

                        <div className="d-flex align-items-center">

                             <div className="back-arrow">
                                <button type="button" className="btn btn-icon">
                                    <span className="text-theme m-icon material-icons">arrow_back</span>
                                </button>
                            </div>

                            <div className="contact-img btn-circle movingLoader  ">
                                
                                  &nbsp;
                            </div>

                            <div className="contact-meta">
                                <span className="contact-name one-line w-100  movingLoader">&nbsp;</span>
                                <div className="info-meta flag  w-100  movingLoader">
                                    <span className="inline-meta m-r-10 one-line">&nbsp;</span>
                                    
                                </div>
                            </div>
                        </div>

                        

                    </div> 
                    <ul className="chat-history">
                        <li>
                            <div className="chat-body me">
                                <div className="chat-info w-50     movingLoader">
                                    <span className= " w-100  movingLoader"></span >
                                </div>
                                <div className="chat-bubble  w-half  movingLoader">
                                    <p> &nbsp;</p>
                                </div>
                            </div>
                        </li> <li>
                            <div className="chat-body me">
                                <div className="chat-info w-50     movingLoader">
                                    <span className=" w-100  movingLoader"></span >
                                </div>
                                <div className="chat-bubble  w-half  movingLoader">
                                    <p> &nbsp;</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="date-divider">
                                <span className="  w-50     movingLoader"></span>
                            </div>
                        </li>

                        <li>
                            <div className="chat-body other">
                                <div className="contact-img w-50     movingLoader">
                                   
                                </div>

                                <div className="d-flex flex-column w-half     movingLoader">
                                    <div className="chat-info">
                                        <span className="chat-name w-100     movingLoader"> </span>
                                        
                                    </div>
                                    <div className="chat-bubble w-lalf     movingLoader">
                                        <p>&nbsp;</p>
                                    </div>
                                </div>

                            </div>
                        </li>

                        <li>
                            <div className="chat-body me">
                                <div className="chat-info w-50     movingLoader">
                                    <span className=" w-100  movingLoader"></span >
                                </div>
                                <div className="chat-bubble  w-half  movingLoader">
                                    <p> &nbsp;</p>
                                </div>
                            </div>
                        </li> <li>
                            <div className="chat-body me">
                                <div className="chat-info w-50     movingLoader">
                                    <span className=" w-100  movingLoader"></span >
                                </div>
                                <div className="chat-bubble  w-half  movingLoader">
                                    <p> &nbsp;</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="date-divider">
                                <span className="  w-50     movingLoader"></span>
                            </div>
                        </li>

                        <li>
                            <div className="chat-body other">
                                <div className="contact-img w-50     movingLoader">

                                </div>

                                <div className="d-flex flex-column w-half     movingLoader">
                                    <div className="chat-info">
                                        <span className="chat-name w-100     movingLoader"> </span>

                                    </div>
                                    <div className="chat-bubble w-lalf     movingLoader">
                                        <p>&nbsp;</p>
                                    </div>
                                </div>

                            </div>
                        </li>   <li>
                            <div className="chat-body me">
                                <div className="chat-info w-50     movingLoader">
                                    <span className=" w-100  movingLoader"></span >
                                </div>
                                <div className="chat-bubble  w-half  movingLoader">
                                    <p> &nbsp;</p>
                                </div>
                            </div>
                        </li> <li>
                            <div className="chat-body me">
                                <div className="chat-info w-50     movingLoader">
                                    <span className=" w-100  movingLoader"></span >
                                </div>
                                <div className="chat-bubble  w-half  movingLoader">
                                    <p> &nbsp;</p>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="date-divider">
                                <span className="  w-50     movingLoader"></span>
                            </div>
                        </li>

                        <li>
                            <div className="chat-body other">
                                <div className="contact-img w-50     movingLoader">

                                </div>

                                <div className="d-flex flex-column w-half     movingLoader">
                                    <div className="chat-info">
                                        <span className="chat-name w-100     movingLoader"> </span>

                                    </div>
                                    <div className="chat-bubble w-half     movingLoader">
                                        <p>&nbsp;</p>
                                    </div>
                                </div>

                            </div>
                        </li>

                    </ul> 
                     

                </div>
                </>}
     
        </>
    );
};

export default UserMessage;
