import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import {  OfferStatusUpdate, CreateAlert } from "../../utils/apiCalls";
import { getMonthDateYear } from "../../utils/addDays"; 
import ModalReason from "./ModalReason";
import Message from "../../components/Message"; 
import ModalOffer from "../../components/JobProposal/ClientOffers";
import ModalContract from "../../components/JobProposal/ClientContract";
import SendMessage from "../../components/Message/SendMessage";
import AlertUtils from "../../utils/AlertUtils";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
import styled from "styled-components";
import { Modal } from "react-bootstrap";

export default function Offers(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [errorMessage, setErrorMessage] = React.useState('');
    const [rowsOffers, setRowsOffers] = useState([]);
    const [id, setId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = React.useState(false);
    const [offermodalvisible, setOffermodalvisible] = useState(false);
    const [contractmodalvisible, setContractmodalvisible] = useState(false);
    const [currentValue, setCurrentValue] = useState({ code: null, id: null });
    const [mode, setMode] = useState("");
    const [showScrollup, setShowScrollup] = useState(null);
    const [offerData, setOfferData] = useState({});
    const [currentMode, setCurrentMode] = useState('');
    const [currentOffer, setCurrentOffer] = useState({});
    const [loaded, setLoaded] = useState(false);
    const [toggleSetting, setToggleSetting] = useState(false);
    const [page, setPage] = useState(1);
    const [modalvisible, setmodalvisible] = useState(false);

    const { slice, range } = tableHelper(rowsOffers, page, gContext.paginationSize);
    const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;
    const togglemodal = () => {
        setmodalvisible(!modalvisible);
    };
    const toggleoffermodal = () => {
        setOffermodalvisible(!offermodalvisible);
    }; 
    const togglecontractmodal = () => {
        
        setContractmodalvisible(!contractmodalvisible);
    }; 
    function toggleModal(data) {
        setOfferData(data);
        setCurrentMode("reject");
        setCurrentOffer(data);
        setShowModal(!showModal);
    }
    function submitflag(flagID) {
        if (flagID) {
            UpdateStatus(300, currentOffer.id, flagID);
        }
    }
    const offerStatusChange = (id, mode,data) => { 
        setShowScrollup(document.documentElement.scrollTop);
        if (id) {
            setOfferData(data);
            setCurrentMode(mode);
            let code = (mode == "withdraw") ? 500 : (mode == "reopen") ? 100 : (mode == "accept") ? 200 : 300;
             
            gContext.setconfirmModal({ header: " ", ["text"]: "Do you want to " + mode+" this offer?", ["confirmed"]: false, code: code , id: id, showButton: true, mode: 'offer' });
            gContext.toggleConfirmModal();
        } 
    }


    useEffect(() => {
        if (gContext.confirmModal.confirmed) {

            if (gContext.confirmModal.mode == "offer") {

                 UpdateStatus(gContext.confirmModal.code, gContext.confirmModal.id,null);
                    gContext.setconfirmModal({
                        ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null, ["code"]: null
                    });
                
            }
            else
                 setCurrentValue({ code: null, id: null });
        }
        else { setCurrentValue({ code: null, id: null }); }
    }, [gContext.confirmModal]);

    async function SendNotificationfromExpert() {

        const profileInfo = gContext.userInfo.profileInfo;
       

        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            let alert = (currentMode === "accept")? AlertUtils.OfferAccepted.
                replace("[EXPERTSUB]", gContext.userInfo.sub).
                replace("[EXPERTNAME]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "").
                replace("[JOBTITLE]", offerData.title).
                replace("[JOBGUID]", offerData.guid) + "<br/> Check <a class='text-theme' href = '" + window.location.origin + "/dashboard' target = '_self'   > dashboard</a> Offer section to Create Contract." : AlertUtils.OfferRejected.
                    replace("[EXPERTSUB]", gContext.userInfo.sub).
                    replace("[EXPERTNAME]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "").
                    replace("[JOBTITLE]", offerData.title).
                    replace("[JOBGUID]", offerData.guid) ;

         
            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 2, toUserId: currentMode === "accept" ? offerData.clientid : currentOffer.clientid});

            if (result.data.createAlert.responseData.code == "200") {
               
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false); 
        }

    }
    async function SendNotificationfromClient() {

        const profileInfo = JSON.parse(gContext.userInfo.profileInfo);
        

        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            const alert = AlertUtils.OfferWithdrawn.
                replace("[CLIENTSUB]", gContext.userInfo.sub).
                replace("[COMPANY]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].company : "").
                replace("[JOBTITLE]", offerData.title).
                replace("[JOBGUID]", offerData.guid);


            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: offerData.expertid });
       
            if (result.data.createAlert.responseData.code == "200") {
                
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
     async function UpdateStatus(code,id,reasonId) {
         try {
             if (code) {
                 gContext.setLoaderModalVisible(true);
                 setErrorMessage("");
                 let result;

                 if (gContext.userInfo.userType == "2") 
                     result = await OfferStatusUpdate(gContext.userInfo.userID, code, id, null, reasonId);
                 else
                     result= await OfferStatusUpdate(null, code, id, gContext.userInfo.userID, reasonId);
                      
                 if (result.data.offerStatusUpdate.responseData.code == "200")
                 {
                     
                     if (currentMode == "accept" || currentMode == "reject")
                         SendNotificationfromExpert();
                     else if (currentMode == "withdraw")
                         SendNotificationfromClient();

                     props.callbackUpdateOffer();
                 }
                 else if (result.data.offerStatusUpdate.responseData.code == "400") {

                     setErrorMessage(JSON.parse( result.data.offerStatusUpdate.responseData.message) );
                 }

                 gContext.setLoaderModalVisible(false);
             }
        }
        catch (e) {
            console.log(e);
         }
         window.scrollTo({
             top: showScrollup,
             behavior: 'smooth'

         });
    }

    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorMessage(true);
        }
        else
            gContext.setErrorModal("");

    }, [errorMessage]);

    function LoadOffer() {
        
           
        try {
            if (props.offerData) {
                setPage(1);
                let data;
                if (props.mode === "new") {

                    data = props.offerData.new;
                   

                }
                else if (props.mode === "Accepted") {

                    data = props.offerData.accepted;

                   

                }
                else if (props.mode === "Rejected") {

                    data = props.offerData.rejected;
                  

                }
                else if (props.mode === "Expired") {

                    data = props.offerData.expired;
                 

                }
                else if (props.mode === "Withdrawn") {

                    data = props.offerData.withdrawn;
                  

                }
                else if (props.mode === "Archived") {

                    data = props.offerData.archived;
                   

                }
                
                setRowsOffers(data);
            }
            else
                setRowsOffers([]);

            setLoaded(true);
        } catch (e) { console.log(e); }
       
    }
    useEffect(() => { 
        LoadOffer();
    }, [props.offerData]);

    useEffect(() => { 
         
        LoadOffer();
    }, [props.mode]);
 

    useEffect(() => {
        LoadOffer();
        gContext.setLoaderModalVisible(false);
    }, []);

    useEffect(() => {

        document.title = "New Offers | Syncuppro";
    }, []);
    return (
        <>
           

                            {message && <div className="col-lg-12 col-xl-12 text-left  ">
                                <Message message="Job deleted successfully" />
                            </div>}
                           
                        
            {(loaded && rowsOffers && rowsOffers.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                <span>No offer found</span>
            </div>}
            {(loaded && rowsOffers && rowsOffers.length > 0) && <>

                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className="w-200 min-lg-cell">  Title</th>
                                <th className="  min-lg-cell">  {gContext.userInfo.userType == "2" ? "Applicant" : "Company"}</th>
                                
                                <th className="  min-lg-cell">Rate</th>
                               

                                <th className="  min-lg-cell">
                                    Weekly Hours</th> 


                                 <th className="  min-lg-cell">
                                    Offered on</th> 

                                {(props.mode == "new") && <th className="  min-lg-cell">Expires on</th>}

                                {(props.mode !== "new") && <th className="  min-lg-cell">
                                    {(props.mode === "Withdrawn") ? "Withdrawn on" : (props.mode === "Archived") ? "Archived on" : (props.mode === "Expired") ? "Expired on " : (props.mode === "Rejected") ? "Rejected on" : (props.mode === "Accepted") ? "Contract Starts on" : (props.mode == "new") ? "Contract Started on" : ""}
                                </th>}



                               

                               
                                {props.mode === "Rejected" && <th  >Reason</th>}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {slice && slice.map((data, index) => {
                                
                                return (
                                    <tr key={index}>
                                        <td>
                                            <span className="d-flex justify-content-left align-items-left">
                                                <span className="title one-line pointer" data-toggle="tooltip" title={data.title} onClick={() => { setErrorMessage(""); setId(data.id); setMode("view"); toggleoffermodal(); }}>{data.title} </span> 
                                            </span>
                                        </td>


                                        

                                        {gContext.userInfo.userType == "2" && <td >


                                            <div className="d-flex justify-content-center align-items-center">
                                                <div className="info-meta company pointer" onClick={(e) => { window.location.href = "/expert/" + data.expertsub; }}   >
                                                    <img className="m-r-10" id={"img"+index } onError={(img) => { img.target.src = '../images/user-default.png'; }}  src={s3URL + data.avatar} alt={data.firstname + ' ' + data.lastname} />
                                                    <span className="one-line text-black ">{data.firstname + ' ' + data.lastname} </span>
                                                    <a   className="m-l-5 text-theme m-icon material-icons">open_in_new</a>
                                                </div>

                                               
                                            </div>
                                           
                                        </td>}
                                        {gContext.userInfo.userType == "1" &&

                                            <td >


                                            <div className="d-flex justify-content-center align-items-center">
                                                    <div className="info-meta company pointer" onClick={(e) => { window.location.href = "/client/" + data.sub; }}   >
                                                        <img className="m-r-10 " id={ "img"+index} src={s3URL + data.logo} onError={(img) => { img.target.src = '../images/user-default.png'; }}   />
                                                    <span className="one-line text-black ">{data.company} </span>
                                                   
                                                    </div>

                                               
                                                </div>

                                            </td>

                                            }

                                        <td className=" text-right">
                                             ${(data.ratetype == 'Daily' && data.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.dailyrate)) : ((data.ratetype == 'Fixed' && data.fixedrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.fixedrate)) : (data.ratetype == 'Hourly' && data.hourlyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.hourlyrate)) : (data.ratefrom && data.rateto) ? new Intl.NumberFormat('en-US').format(parseInt(data.ratefrom)) + ' - ' + new Intl.NumberFormat('en-US').format(parseInt(data.rateto)) : '-')}</td>
                                        
                                        
                                        <td>{data.weeklyhrs}</td>  


                                         <td> {data.offerdate ? getMonthDateYear(data.offerdate) : ""}</td>   
                                        
                                         
                                        {(data.statuscode == 100) && <td> {data.offerenddate ? getMonthDateYear(data.offerenddate):"" }
                                        </td>
                                        }

                                        {(data.statuscode != 100) && <td>{(data.statuscode == 300) || (data.statuscode == 500) || (data.statuscode == 600) && data.datemodified ? getMonthDateYear(data.datemodified) : (data.statuscode == 400) ? getMonthDateYear(data.offerenddate) : getMonthDateYear(data.startdate)}
                                        </td>}
                                        
                                       
                                        {props.mode === "Rejected" &&
                                            <td >
                                            {data.reason}
                                            </td>}
                                        <td>
                                            <div className="btn-table">


                                                
                                                {gContext.userInfo.userType == "1" && <>

                                                    {props.mode == "new" && <>

                                                        <button className="btn btn-sm btn-gray"  onClick={() => { setErrorMessage(""); offerStatusChange(data.id, 'accept', data) }}>
                                                            Accept</button>
                                                        <button className="btn btn-sm btn-gray"  onClick={() => { setErrorMessage(""); toggleModal(data) }}>
                                                            Reject</button>
                                                        </>}
                                                </>}
                                                {gContext.userInfo.userType == "2" &&
                                                    <>
                                                        {props.mode !== "Expired" && props.mode !== "Withdrawn" && props.mode !== "Accepted" && props.mode !== "Archived" &&

                                                        <button className="btn btn-sm btn-gray"  onClick={() => { setId(data.id); setMode("edit"); toggleoffermodal(); setErrorMessage(""); }}>
                                                        Edit </button>
                                                         } {gContext.userInfo.userType == "2" && props.mode === "Accepted" &&
                                                        <>
                                                        
                                                        <button className="btn btn-sm btn-gray"  onClick={() => { if (gContext.userInfo.paymentConnected) { setErrorMessage(""); setId(data.id); togglecontractmodal(); } else { setToggleSetting(true); } }}>
                                                            Create Contract</button> 


                                                        </>}
                                                    {gContext.userInfo.userType == "2" && (props.mode === "Accepted" || props.mode === "new") &&
                                                        <>
                                                            <button className="btn btn-sm btn-gray" onClick={() => { setOfferData(data); togglemodal(); }}>
                                                                Message</button>
                                                            
                                                        </>}
                                                   
                                                    {props.mode !== "Expired" && props.mode !== "Withdrawn" && props.mode !== "Archived" &&

                                                        <button className="btn btn-sm btn-gray"  onClick={() => { setErrorMessage(""); offerStatusChange(data.id, 'withdraw', data); }}>
                                                        Withdraw </button> 
                                                             }
                                                        {(gContext.userInfo.userType == "2" && (props.mode === "Withdrawn" || props.mode === "Expired")) &&

                                                        <button className="btn btn-sm btn-gray"  onClick={() => offerStatusChange(data.id, 'reopen', data)} >
                                                        Reopen</button> 

                                                        }</>

                                                     }

                                            </div>
                                        </td>
                                    </tr>)
                            })}</tbody>
                    </table>
                </div>
                {(loaded && rowsOffers && rowsOffers.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}



                 </>}
            {modalvisible && <SendMessage userdata={offerData} modalvisible={modalvisible} togglemodal={togglemodal} />}
            {offermodalvisible && <ModalOffer id={id} mode={mode} proposalData={props.proposalData} offermodalvisible={offermodalvisible} toggleoffermodal={toggleoffermodal} updateData={() => LoadOffer()} createContract={(id) => { if (gContext.userInfo.paymentConnected) { setErrorMessage(""); setId(id); togglecontractmodal(); } else { setToggleSetting(true); } }} updateStatus={(id, code, data) => offerStatusChange(id, code, data)} toggleModal={(data) => toggleModal(data)} />}
            {contractmodalvisible && <ModalContract id={id} mode="add" contractmodalvisible={contractmodalvisible} togglecontractmodal={() => { togglecontractmodal(); }} updateData={() => LoadOffer()} />}
            {showModal && <ModalReason submitflag={submitflag} modalvisible={showModal} togglemodal={toggleModal} />}
            <ModalStyled size="sm"
                centered
                show={toggleSetting}
                onHide={()=>setToggleSetting(!toggleSetting)}
            >

                        <div className="modal-body text-center">
                    <img className="m-b-20" src="../images/unsuccess-cross.svg" alt="error" />
                            <h5 className="m-b-10">Error!</h5>
                            <p className="text-light-gray">Your payment account is not connected. Update payment details in settings page. </p>

                            <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-orange m-r-10" onClick={() => { window.location.href = "/settings"; }}>Settings</button> <button type="button" className="btn btn-dark-outline " onClick={() => { setToggleSetting(!toggleSetting); }}>Ok</button>
                            </div>

                        </div>
                </ModalStyled >
        </>
    );
};