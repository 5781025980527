import React, { useContext, useState, useEffect } from "react";
import StarRating from "../../components/Core/Rating";
import { sinceDate } from "../../utils/addDays";
import GlobalContext from "../../context/GlobalContext";
import DOMPurify from 'isomorphic-dompurify';
import ReactCountryFlag from "react-country-flag"; 
import ModalJobProposal from "../../components/JobProposal/JobProposal";
import ModalPostedJobs from "../../sections/ClientDashboard/ModalInvite";
export default function ExpertSearchlist(props) {
    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [id, setID] = useState(null);
    const [proposalModalVisible, setProposalModalVisible] = useState(false);
    const [experts, setExperts] = useState([{}]);
    const [expertData, setExpert] = useState({});     
    const [showJobs, setShowJobs] = useState(false);
    
    function toRoundOff(n) {
            //var result = ; 

            if ((n - Math.floor(n)) != 0) {
                var dec_value = parseFloat(n).toFixed(1);
                return dec_value;

            }
            else {
                return n;
            }

        }
   
    const toggleProposalModal = () => {
        setProposalModalVisible(!proposalModalVisible);
    };


    useEffect(() => {
        
        setExperts(props.jobsList);
    }, [props.jobsList]);
    useEffect(() => {

    }, [gContext.userInfo]);
    return (
        <>
            {(experts) && experts.map((expert, index) => {

                return (
                    <>

                    <div className="row" key={index}>
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card list-block">
                                <div className="expert-info-details">
                                    <div className="expert-data">
                                        <div className="expert-profile m-0 align-items-start">
                                            <div className="expert-img">
                                                 
                                                    {(expert.avatar) && <img className="pointer" src={(expert.avatar && expert.avatar != "" && expert.avatar.toLowerCase() != "null") ? s3URL + expert.avatar : "../images/user-default.png"} onClick={() => { window.location.href = "expert/" + expert.sub; }} id={"image" + index} onError={(img) => { img.target.src = '../../images/user-default.png'; }}  />}
                        </div>

                                                <div className="d-flex flex-column hide-md">

                                                    <div className="d-flex"><strong onClick={() => { window.location.href = "expert/" + expert.sub; }} ><h1 className=" expert-name pointer" onClick={() => { window.location.href = "expert/" + expert.sub; }}> {(expert.firstname) ? expert.firstname : ''} {" "}   {(expert.lastname) ? expert.lastname : ''}</h1></strong><img className="verified-img m-l-5" src="images/verified_img.svg" alt="verified_img.svg" /></div> 

                                               
                                                        <div className="d-flex ">
                                                    <span className="expert-desg">{(expert.title) ? expert.title : ''}</span>

                                                            <div className="info-meta flag">
                                                        <span className="text-theme m-icon material-icons m-r-10">location_on</span><span className="one-line text-black m-r-10"> 
                                                        <span className={`text-black ${(expert.city != null && expert.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{expert.city != null?expert.city.toLowerCase():''}</span>
                                                        
                                                        , {expert.countrycode}</span>
                                                        <ReactCountryFlag
                                                            countryCode={expert.countrycode}
                                                            svg

                                                        />      
                            </div>
                                                       
                                                            </div>

                                                    {(expert.avgrating == null) ? '' :
                                                        <>     <div className="ratings m-t-0">
                                                            <StarRating rating={(expert.avgrating) ? expert.avgrating : 0} />
                                                            <span className="m-l-5 m-t-10">{(expert.avgrating) ? toRoundOff (expert.avgrating) + "/5" : 0}</span>
                                                        </div></>}

                                                </div>
                                                <div className="d-flex flex-column d-none hide-lg show-md">

                                                    <strong className="expert-name pointer" onClick={() => { window.location.href ="expert/" + expert.sub }}> {(expert.firstname) ? expert.firstname : ''} {" "}   {(expert.lastname) ? expert.lastname : ''}<img className="verified-img m-l-5" src="images/verified_img.svg" alt="verified_img.svg" /></strong>


                                                    <div className="">
                                                        <span className="expert-desg">{(expert.title) ? expert.title : ''}</span>

                                                        <div className="info-meta flag">
                                                            <span className="text-theme m-icon material-icons m-l-0 m-r-10">location_on</span><span className="one-line text-black m-r-10">
                                                                <span className={`text-black ${(expert.city != null && expert.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{expert.city != null ? expert.city.toLowerCase() : ''}</span>

                                                                , {expert.countrycode}</span>
                                                            <ReactCountryFlag
                                                                countryCode={expert.countrycode}
                                                                svg

                                                            />
                                                        </div>

                                                    </div>

                                                    {(expert.avgrating == null) ? '' :
                                                        <>     <div className="ratings m-t-0">
                                                            <StarRating rating={(expert.avgrating) ? expert.avgrating : 0} />
                                                            <span className="m-l-5 m-t-10">{(expert.avgrating) ? toRoundOff(expert.avgrating) + "/5" : 0}</span>
                                                        </div></>}

                                                </div>
                                            </div>
                                             

                                    </div>
                                    

                                                <div className="work-pay">
                                        {(expert.dailyrate) ? <>  <strong>${new Intl.NumberFormat('en-US').format(parseInt(expert.dailyrate))}</strong><span className="text-sm">Daily</span></> : ''}
                                                </div>

                                            </div> 

                  <div className="month-data text-light-gray">
                                        <span>Joined <strong> {sinceDate(expert.datecreated, new Date())}</strong></span>    
                                            </div>

                                    {(expert.certificates && expert.certificates.length > 0) ? <div className="certified-list" style={{ width: "max-content", overflowWrap: "break-word" }}>
                                        <b>  
                                            Certified To:</b> {(expert.certificates) && expert.certificates.map((data, index) => {

                                                return (<span key={index}>  {(expert.certificates.length - 1 !== index) ? data.name + ", " : data.name}</span>)
                                            })}
                                    </div> : <div className="certified-list" style={{width:"max-content"}}>
                                            <b>  
                                            Certified To:</b>   No Certificate available 
                                        </div>}
                                            <div className="type-name"> 
                                                
                                    {(expert.explevel) && expert.explevel.map((data, index) => {
                                        return (<div className="info-meta" key={ index}>
                                            <span className="text-theme m-icon material-icons m-r-10">work</span><span>{(data.level) ? data.level : ''}</span>
                                        </div>)
                                    })}

                                                <div className="info-meta">
                                        <span className="text-theme m-icon material-icons m-r-10">calendar_month</span><span>{(expert.weeklyhours) ? expert.weeklyhours : ''}</span>
                                                </div>
                                            </div> 

                                <p className="text-gray three-line m-b-20">
                                    {(expert.profile) ? expert.profile.replace(/(<([^>]+)>)/ig, '').replaceAll("&nbsp", "") : ""}
                                                
                  </p>

                                <div className="badge-group">

                                    {(expert.skillidslist) && expert.skillidslist.map((data, index) => {

                                        return ( 
                                            <span key={index} className="badge skills"> {data.skill}</span>
                                            

                                         )
                                    })}
                                    {(expert.compliancecategoryidslist) && expert.compliancecategoryidslist.map((data, index) => {

                                        return (
                                            <span key={index} className="badge compliance-category"> {data.category}</span>
                                            )
                                    })}
                                    {(expert.complianceidslist) && expert.complianceidslist.map((data, index) => {

                                        return (<span key={index} className="badge compliance-standard"> {data.code}</span>
                                             )
                                    })}

                                               
                                            </div> 


                  <div className="expert-action">
                                        <a href={"expert/" + expert.sub} target="_self" className="btn btn-link">View More Details<span className="m-icon material-icons m-l-10">arrow_forward</span></a>

                                        <div className="action-btns">

                                            {gContext.userInfo.userID && gContext.userInfo.userType == '2' && gContext.userInfo.profileInfo && JSON.parse(gContext.userInfo.profileInfo).length > 0 && !JSON.parse(gContext.userInfo.profileInfo)[0].flagged && <button className="btn btn-orange" onClick={() => { setShowJobs(true); setExpert(expert); }}  >
                                                
                                                Invite  &nbsp;  
                                            </button> }

                                            {!localStorage.getItem("connect") && <button className="btn btn-orange  rounded-10 "

                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    gContext.toggleSignInModal();
                                                }}   >
                                                Get a FREE Quote
                                            </button>}
                                           
                                        </div>

                                    </div>


                                    

                                        </div> 
              </div>
                                </div>

                    <div className="mb-8 d-none">
                        {/* <!-- Single Featured Job --> */}
                        <div className="pt-9 px-xl-9 px-lg-7 px-7 pb-7 light-mode-texts bg-white rounded hover-shadow-3 ">
                            <div className="row">
                                <div className="col-md-8  ">
                                    <div className="media align-items-center w-100">
                                        <div className="d-block mr-8 mt-n15">
                                            <a href={"expert/" + expert.sub} target="blank">
                                                    {(expert.avatar) && <img src={s3URL + expert.avatar} id={"image" + index} onError={(img) => { img.target.src = '../images/user-default.png'; }} className=" w-100 circle-72 " alt="" />}
                                            </a>

                                        </div>
                                        <div className=" w-100">
                                            <h3 className="mt-0 mb-0">

                                                <a className="font-size-6 heading-default-color" href={"expert/" + expert.sub} rel="noopener noreferrer" target="blank">
                                                    {(expert.firstname) ? expert.firstname : ''} {" "}   {(expert.lastname) ? expert.lastname : ''}
                                                </a>

                                            </h3>

                                            <span className="font-size-4  text-default-color line-height-2">
                                                {(expert.title) ? expert.title : ''} <i className="icon icon-pin-3   mr-1 font-weight-bold"></i>
                                                <span className="font-size-3 font-weight-normal">  
                                                <span className={`text-black ${(expert.city != null && expert.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{expert.city != null?expert.city.toLowerCase():''}</span> 
                                               
                                                 
                                                 , {expert.countrycode}  </span>
                                            </span>
                                            <ul className="d-flex list-unstyled mr-n8 flex-wrap  justify-content-md-start  w-100">
                                            {(expert.avgrating == null) ? '' :
                                                <>
                                                    <StarRating rating={(expert.avgrating) ? expert.avgrating : 0} />
                                                    <span className="  pl-3 pr-5 font-size-3">{(expert.avgrating) ? expert.avgrating.toString() + "/5" : 0}
                                                    </span></>}

                                               
                                               <span className="font-size-3"> Joined&nbsp;
                                               <a className="bg-regent-opacity-15 text-gray font-size-3 rounded-3  px-2">
                                                    <i className="fa fa-clock mr-2 font-weight-bold"></i>{" "} </a></span>
                                                   <span className="text-black"> {sinceDate(expert.datecreated, new Date())}</span>
                                               
                                            </ul>
                                            

                                                   

                                            
                                            <ul className="d-flex list-unstyled mr-n3 flex-wrap  justify-content-md-start  w-100 ">


                                                {(expert.explevel) && expert.explevel.map((data, index) => {

                                                    return (<li key={index} className="mt-2 mr-5 ">

                                                        <a className="bg-regent-opacity-15 text-green-2 font-size-3 rounded-3">
                                                            <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                            {(data.level) ? data.level : ''}
                                                        </a>


                                                    </li>)
                                                })}


                                                <li className="mt-2 mr-5 ">
                                                    <a className="bg-regent-opacity-15 text-orange font-size-3 rounded-3 px-2">
                                                        <i className="fa fa-briefcase mr-2 font-weight-bold"></i>{" "}
                                                        {(expert.weeklyhours) ? expert.weeklyhours : ''}
                                                    </a>
                                                </li>

                                              


                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 text-right pt-3  pt-md-3">
                                    <div className="media justify-content-md-end">

                                        <div className="image  pl-7 ">
                                            {(expert.dailyrate) ? <>
                                                <p className="font-weight-bold font-size-6 text-hit-gray mb-0">
                                                    <i className="fa-solid fa-dollar font-size-6 text-green-2"></i><span className="text-black-2"> {new Intl.NumberFormat('en-US').format(parseInt(expert.dailyrate))}</span>&nbsp;<a className="font-weight-normal font-size-3 text-hit-gray mb-0">Daily</a>
                                                </p></> : ''}
                                        </div>


                                    </div>
                                </div>
                            </div>

                            <div className="row ">
                                <a href={"expert/" + expert.sub} rel="noopener noreferrer" target="blank" className="w-100">  <div className="col-md-12 pb-5 heading-default-color w-100" style={{ textOverflow: 'ellipsis', height: '73px', width: "100%", overflow: 'hidden', wordWrap: "break-word" }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(expert.profile).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}>

                                </div>

                                    {(DOMPurify.sanitize(expert.profile).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``).length > 270) && <div className="mt-n7  mb-7 text-right w-100"><i className="fa-solid fa-ellipsis mr-9"></i> </div>}</a>
                                <div className="col-md-12">
                                    <ul className="d-flex list-unstyled mr-n3 flex-wrap">

                                        {(expert.skillidslist) && expert.skillidslist.map((data, index) => {

                                            return (<li key={index}>

                                                <a className="bg-orange-opacity-2 min-width-px-96 mr-3 text-center rounded-15 px-6 py-1 font-size-3 text-black-2 mt-2">
                                                    {data.skill}
                                                </a>

                                            </li>)
                                        })}
                                        {(expert.compliancecategoryidslist) && expert.compliancecategoryidslist.map((data, index) => {

                                            return (<li key={index}>

                                                <a className="bg-green-opacity-2 min-width-px-96 mr-3 text-center rounded-15 px-6 py-1 font-size-3 text-black-2 mt-2">
                                                    {data.category}
                                                </a>

                                            </li>)
                                        })}
                                        {(expert.complianceidslist) && expert.complianceidslist.map((data, index) => {

                                            return (<li key={index}>

                                                <a className="bg-yellow-2-opacity-2  min-width-px-96 mr-3 text-center rounded-15 px-6 py-1 font-size-3 text-black-2 mt-2">
                                                    {data.code}
                                                </a>

                                            </li>)
                                        })}
                                    </ul>
                                </div>

                            </div>

                        </div>
                        {/* <!-- End Single Featured Job --> */}
                    </div></>

                )
            })}

            {showJobs && <ModalPostedJobs expertData={expertData} modalvisible={showJobs} toggleModal={() => { setShowJobs(!showJobs); }} />}
            <ModalJobProposal id={id} proposalModalVisible={proposalModalVisible} toggleProposalModal={toggleProposalModal} />

        </>
    );
};
